<template>
  <div style="margin-top: 0px;min-height: 500px;">
    <div class="container" v-if="companyId">
      <Suspense>
        <template #default>
          <enterprise-details-title v-if="companyId" @loginEmit="loginEmit" :companyId="companyId"></enterprise-details-title>
        </template>
        <template #fallback>
          <h1>Loading...</h1>
        </template>
      </Suspense>
      <el-affix position="top" :offset="0">
      <el-popover
        placement="bottom-start"
        :show-after="500"
        :width="1175"
        trigger="hover"
        :show-arrow="showArrow"
        :offset="0"
        v-model:visible="visible"
      >
        <template #reference>
          <div class="nav-list-content dis-bet-cen">
            <div class="nav-list-content-li" v-for="(item, index) of navList" @click="returnTopNav(item.value, index, item.num)" :class="{active:btnActive == index}" :key="index">{{item.name}} {{item.num > 999 ? '999+' : item.num || ''}}</div>
          </div>
        </template>
        <div class="dis-bet-sta" style="position: relative;">
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of oneList" @click="item.num > 0 || item.value =='gs' ? returnTop(item.value, '0') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of twoList" @click="item.num > 0 ? returnTop(item.value, '1') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of threeList" @click="item.num > 0 ? returnTop(item.value, '2') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of fourList" @click="item.num > 0 ? returnTop(item.value, '3') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of fiveList" @click="item.num > 0 ? returnTop(item.value, '4') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of sixList" @click="item.num > 0 ? returnTop(item.value, '5') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
          <div class="nav-list-content-list">
            <div class="nav-list-content-list-div" :class="{active:item.num == 0}" v-for="(item, index) of sevenList" @click="item.num > 0 ? returnTop(item.value, '6') : returnTopEmpty()" :key="index">{{item.name}}<span class="font-color-div" :class="{active:item.num == 0}">{{item.num}}</span></div>
          </div>
        </div>
      </el-popover>
      </el-affix>
      <div class="middle-content-div" v-if="companyId">
        <!-- 工商信息 -->
        <block>
          <div id="gs" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div class="middle-content-div-title">工商信息</div>
          <el-descriptions class="margin-top" :column="2" :size="size" border>
            <el-descriptions-item :span="1">
              <template #label>企业名称</template>
              {{companyDetail.companyName || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>工商注册号</template>
              {{companyDetail.businessLicense || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>统一信用代码</template>
              {{companyDetail.code || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>法定代表人</template>
              {{companyDetail.corporation || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>组织机构代码</template>
              {{companyDetail.organizationCode || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>企业类型</template>
              {{companyDetail.registrationType || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>所属行业</template>
              {{(companyDetail.tEciIndustry ? (companyDetail.tEciIndustry.industry ? companyDetail.tEciIndustry.industry : '-') : '-') || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>经营状态</template>
              {{companyDetail.registerStat || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>注册资本</template>
              {{companyDetail.regMoney || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>实缴资本</template>
              {{companyDetail.realCapital || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>注册时间</template>
              {{companyDetail.regDate || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>营业期限</template>
              {{companyDetail.operatePeriodfrom ? companyDetail.operatePeriodfrom.substring(0, 10) : '-' || '-'}}至{{companyDetail.operatePeriodto ? companyDetail.operatePeriodto.substring(0, 10) : '无固定期限' || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>登记机关</template>
              {{companyDetail.registAuthority || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>核准日期</template>
              {{companyDetail.issueDate ? companyDetail.issueDate.substring(0, 10) : '-' || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>参保人数</template>
              {{companyDetail.ylIns || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>注册地址</template>
              {{companyDetail.regAddress || '-'}}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template #label>经营范围</template>
              <div style="width: 1000px;">{{companyDetail.businessScope || '-'}}</div>
            </el-descriptions-item>
          </el-descriptions>
        </block>
        <!-- 股东信息 -->
        <Suspense>
          <template #default>
            <partner-count v-if="oneList[1]?.num && oneList[1]?.num * 1 > 0" :companyId="companyId"></partner-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <!-- 主要人员 -->
        <Suspense>
          <template #default>
            <employee-count v-if="oneList[2]?.num && oneList[2]?.num * 1 > 0" :companyId="companyId"></employee-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <!-- 变更记录 -->
        <Suspense>
          <template #default>
            <change-count v-if="oneList[3]?.num && oneList[3]?.num * 1 > 0" :companyId="companyId"></change-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <!-- 分支机构 -->
        <Suspense>
          <template #default>
            <branch-count v-if="oneList[4]?.num && oneList[4]?.num * 1 > 0" :companyId="companyId"></branch-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <div v-if="navList[0].num === 0">
          <div id="gs" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[0].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[0].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 资质证书 -->
        <Suspense>
          <template #default>
            <credential-count v-if="twoList[0]?.num && twoList[0]?.num * 1 > 0" @loginEmit="loginEmit" :companyId="companyId" :num="twoList[0].num"></credential-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <!-- 荣誉信息 企业荣誉 -->
        <Suspense>
          <template #default>
            <honor-image-count @loginEmit="loginEmit" v-if="(twoList[1]?.industryHonorImageCount && twoList[1]?.industryHonorImageCount * 1 > 0) || (twoList[1]?.commercialHonorImageCount && twoList[1]?.commercialHonorImageCount * 1 > 0) " :companyId="companyId" :num="twoList[1].industryHonorImageCount + twoList[1].commercialHonorImageCount"></honor-image-count>
          </template>
          <template #fallback>
            <h1>Loading...</h1>
          </template>
        </Suspense>
        <!-- 荣誉信息 税务荣誉 -->
        <honor-tax-count @loginEmit="loginEmit" v-if="twoList[1]?.taxHonorImageCount && twoList[1]?.taxHonorImageCount * 1 > 0" :companyId="companyId" :num="twoList[1].taxHonorImageCount"></honor-tax-count>
        <!-- 技术人员 -->
        <registered-personnel-count @loginEmit="loginEmit" v-if="twoList[2]?.num && twoList[2]?.num * 1 > 0" :companyId="companyId" :num="twoList[2].num"></registered-personnel-count>
        <!-- 人员业绩 -->
        <company-personnel-results-count @loginEmit="loginEmit" v-if="twoList[3]?.num && twoList[3]?.num * 1 > 0" :companyId="companyId" :num="twoList[3].num"></company-personnel-results-count>
        <!-- 中标信息 -->
        <achievement-count @loginEmit="loginEmit" v-if="twoList[4]?.num && twoList[4]?.num * 1 > 0" :companyId="companyId" :num="twoList[4].num"></achievement-count>
        <!-- 备案信息 -->
        <filing-count @loginEmit="loginEmit" v-if="twoList[6]?.num && twoList[6]?.num * 1 > 0" :companyId="companyId" :num="twoList[6].num"></filing-count>
        <div v-if="navList[1].num === 0">
          <div id="credentialCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[1].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[1].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 失信信息 -->
        <shi-xin-count @loginEmit="loginEmit" v-if="threeList[0]?.num && threeList[0]?.num * 1 > 0" :companyId="companyId" :num="threeList[0].num"></shi-xin-count>
        <!-- 被执行人 -->
        <zhi-zing-count @loginEmit="loginEmit" v-if="threeList[1]?.num && threeList[1]?.num * 1 > 0" :companyId="companyId" :num="threeList[1].num"></zhi-zing-count>
        <!-- 经营异常 -->
        <manage-operateexception-count @loginEmit="loginEmit" v-if="threeList[2]?.num && threeList[2]?.num * 1 > 0" :companyId="companyId" :num="threeList[2].num"></manage-operateexception-count>
        <!-- 工程建设违法 -->
        <project-operateexception-count @loginEmit="loginEmit" v-if="threeList[3]?.num && threeList[3]?.num * 1 > 0" :companyId="companyId" :num="threeList[3].num"></project-operateexception-count>
        <div v-if="threeList[4]?.greatCount && threeList[4]?.greatCount * 1 > 0 || threeList[4]?.taxesCount && threeList[4]?.taxesCount * 1 > 0 || threeList[4]?.otherTaxesCount && threeList[4]?.otherTaxesCount * 1 > 0" style="color: #333;font-weight: bold;">税务违法</div>
        <!-- 税务违法 重大税务违法 -->
        <administrative-penalty-count @loginEmit="loginEmit" v-if="threeList[4]?.greatCount && threeList[4]?.greatCount * 1 > 0" :companyId="companyId" :num="threeList[4].greatCount"></administrative-penalty-count>
        <!-- 欠税公告 -->
        <administrative-report-count @loginEmit="loginEmit" v-if="threeList[4]?.taxesCount && threeList[4]?.taxesCount * 1 > 0" :companyId="companyId" :num="threeList[4].taxesCount"></administrative-report-count>
        <!-- 其他税务违法 -->
        <administrative-other-count @loginEmit="loginEmit" v-if="threeList[4]?.otherTaxesCount && threeList[4]?.otherTaxesCount * 1 > 0" :companyId="companyId" :num="threeList[4].otherTaxesCount"></administrative-other-count>
        <div v-if="threeList[5]?.illegalBidCount && threeList[5]?.illegalBidCount * 1 > 0 || threeList[5]?.otherBiddingCount && threeList[5]?.otherBiddingCount * 1 > 0" style="color: #333;font-weight: bold;">招投标违法</div>
        <!-- 招投标违法 -->
        <illegal-bid-count @loginEmit="loginEmit" v-if="threeList[5]?.illegalBidCount && threeList[5]?.illegalBidCount * 1 > 0" :companyId="companyId" :num="threeList[5].illegalBidCount"></illegal-bid-count>
        <!-- 其他招投标违法 -->
        <illegal-other-count @loginEmit="loginEmit" v-if="threeList[5]?.otherBiddingCount && threeList[5]?.otherBiddingCount * 1 > 0" :companyId="companyId" :num="threeList[5].otherBiddingCount"></illegal-other-count>
        <!-- 其他行政处罚 -->
        <other-punishment-count @loginEmit="loginEmit" v-if="threeList[6]?.num && threeList[6]?.num * 1 > 0" :companyId="companyId" :num="threeList[6].num"></other-punishment-count>
        <div v-if="navList[2].num === 0">
          <div id="shixinCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[2].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[2].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 商标信息 -->
        <tm-count v-if="fourList[0]?.num && fourList[0]?.num * 1 > 0" :companyId="companyId"></tm-count>
        <!-- 专利信息 -->
        <patent-count v-if="fourList[1]?.num && fourList[1]?.num * 1 > 0" :companyId="companyId"></patent-count>
        <!-- 作品著作权 -->
        <copyright-count v-if="fourList[2]?.num && fourList[2]?.num * 1 > 0" :companyId="companyId"></copyright-count>
        <!-- 软著著作权 -->
        <softwarecopyright-count v-if="fourList[3]?.num && fourList[3]?.num * 1 > 0" :companyId="companyId"></softwarecopyright-count>
        <div v-if="navList[3].num === 0">
          <div id="tmCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[3].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[3].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 对外投资 -->
        <investinfo-count v-if="fiveList[0]?.num && fiveList[0]?.num * 1 > 0" :companyId="companyId"></investinfo-count>
        <!-- 股权出质 -->
        <pledge-count v-if="fiveList[1]?.num && fiveList[1]?.num * 1 > 0" :companyId="companyId"></pledge-count>
        <!-- 清算信息 -->
        <liquidation-count v-if="fiveList[2]?.num && fiveList[2]?.num * 1 > 0" :companyId="companyId"></liquidation-count>
        <!-- 抽查检查 -->
        <spotcheck-count v-if="fiveList[3]?.num && fiveList[3]?.num * 1 > 0" :companyId="companyId"></spotcheck-count>
        <!-- 招聘信息 -->
        <recruitinfo-count v-if="fiveList[4]?.num && fiveList[4]?.num * 1 > 0" :companyId="companyId"></recruitinfo-count>
        <!-- 新闻信息 -->
        <newsinfo-count v-if="fiveList[5]?.num && fiveList[5]?.num * 1 > 0" :companyId="companyId"></newsinfo-count>
        <!-- 进出口信用 -->
        <import-export-count v-if="fiveList[6]?.num && fiveList[6]?.num * 1 > 0" :companyId="companyId"></import-export-count>
        <!-- 地块公示 -->
        <land-publish-count v-if="fiveList[7]?.num && fiveList[7]?.num * 1 > 0" :companyId="companyId"></land-publish-count>
        <!-- 购地信息 -->
        <land-purchase-count v-if="fiveList[8]?.num && fiveList[8]?.num * 1 > 0" :companyId="companyId"></land-purchase-count>
        <!-- 土地转让 -->
        <land-transfer-count v-if="fiveList[9]?.num && fiveList[9]?.num * 1 > 0" :companyId="companyId"></land-transfer-count>
        <!-- 债券信息 -->
        <bond-count v-if="fiveList[10]?.num && fiveList[10]?.num * 1 > 0" :companyId="companyId"></bond-count>
        <!-- 企业年报 -->
        <annual-report-count v-if="fiveList[11]?.num && fiveList[11]?.num * 1 > 0" :companyId="companyId"></annual-report-count>
        <div v-if="navList[4].num === 0">
          <div id="tmCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[4].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[4].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 法律诉讼 -->
        <judgement-count v-if="sixList[0]?.num && sixList[0]?.num * 1 > 0" :companyId="companyId"></judgement-count>
        <!-- 法院公告 -->
        <court-count v-if="sixList[1]?.num && sixList[1]?.num * 1 > 0" :companyId="companyId"></court-count>
        <!-- 建议注销 -->
        <simplecancellation-count v-if="sixList[2]?.num && sixList[2]?.num * 1 > 0" :companyId="companyId"></simplecancellation-count>
        <!-- 土地抵押 -->
        <land-mortgage-count v-if="sixList[3]?.num && sixList[3]?.num * 1 > 0" :companyId="companyId"></land-mortgage-count>
        <!-- 公示催告 -->
        <!-- <publish-notice-count v-if="sixList[4]?.num && sixList[4]?.num * 1 > 0" :companyId="companyId"></publish-notice-count> -->
        <!-- 动产抵押 -->
        <movablespledge-count v-if="sixList[4]?.num && sixList[4]?.num * 1 > 0" :companyId="companyId"></movablespledge-count>
        <!-- 开庭公告 -->
        <courtnotice-count v-if="sixList[5]?.num && sixList[5]?.num * 1 > 0" :companyId="companyId"></courtnotice-count>
        <!-- 送达公告 -->
        <delivery-notice-count v-if="sixList[6]?.num && sixList[6]?.num * 1 > 0" :companyId="companyId"></delivery-notice-count>
        <!-- 立案信息 -->
        <register-count v-if="sixList[7]?.num && sixList[7]?.num * 1 > 0" :companyId="companyId"></register-count>
        <!-- 司法协助 -->
        <assistance-count v-if="sixList[8]?.num && sixList[8]?.num * 1 > 0" :companyId="companyId"></assistance-count>
        <div v-if="navList[5].num === 0">
          <div id="judgementCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[5].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[5].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
        <!-- 基本信息 -->
        <info-count v-if="sevenList[0]?.num && sevenList[0]?.num * 1 > 0" :companyId="companyId"></info-count>
        <!-- 十大股东 -->
        <toptenholder-count v-if="sevenList[1]?.num && sevenList[1]?.num * 1 > 0" ref="toptenholderCountRef" :companyId="companyId"></toptenholder-count>
        <!-- 核心题材 -->
        <!-- <core-theme-count v-if="sevenList[2]?.num && sevenList[2]?.num * 1 > 0" ref="coreThemeCountRef" :companyId="companyId"></core-theme-count> -->
        <!-- 对外担保 -->
        <important-dwdb-count v-if="sevenList[2]?.num && sevenList[2]?.num * 1 > 0" ref="importantDwdbCountRef" :companyId="companyId"></important-dwdb-count>
        <!-- 违规处理 -->
        <important-wgcl-count v-if="sevenList[3]?.num && sevenList[3]?.num * 1 > 0" ref="importantWgclCountRef" :companyId="companyId"></important-wgcl-count>
        <!-- 招股书 -->
        <prospectus-count v-if="sevenList[4]?.num && sevenList[4]?.num * 1 > 0" ref="prospectusCountRef" :companyId="companyId"></prospectus-count>
        <!-- 主要指标 -->
        <main-indicator-count v-if="sevenList[5]?.num && sevenList[5]?.num * 1 > 0" ref="mainIndicatorCountRef" :companyId="companyId"></main-indicator-count>
         <!--发行相关 -->
        <info-issue-count v-if="sevenList[6]?.num && sevenList[6]?.num * 1 > 0" ref="infoIssueCountRef" :companyId="companyId"></info-issue-count>
        <!-- 利润表 -->
        <income-statement-count v-if="sevenList[7]?.num && sevenList[7]?.num * 1 > 0" ref="incomeStatementCountRef" :companyId="companyId"></income-statement-count>
         <!--资产负债 -->
        <balance-sheet-count v-if="sevenList[8]?.num && sevenList[8]?.num * 1 > 0" ref="balanceSheetCountRef" :companyId="companyId"></balance-sheet-count>
        <!-- 现金流量 -->
        <cash-flow-statement-count v-if="sevenList[9]?.num && sevenList[9]?.num * 1 > 0" ref="cashFlowStatementCountRef" :companyId="companyId"></cash-flow-statement-count>
        <div v-if="navList[6].num === 0">
          <div id="infoCount" style="position: relative;height: 0;overflow: hidden;top: -140px;"></div>
          <div style="margin: 20px 0;color: #333;font-weight: bold;">{{navList[6].name}}</div>
          <div style="border: 1px solid #ebebeb;">
            <el-empty :description="'暂无' + navList[6].name + '数据'" :image-size="200"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineAsyncComponent } from 'vue'
// const EnterpriseDetailsTitle = defineAsyncComponent(() => import('@/components/enterprise-details-title/enterprise-details-title'))
import EnterpriseDetailsTitle from '@/components/enterprise-details-title/enterprise-details-title'
import PartnerCount from '@/components/enterprise-details/partner-count' // 股东信息
import EmployeeCount from '@/components/enterprise-details/employee-count' // 主要人员
import ChangeCount from '@/components/enterprise-details/change-count' // 变更记录
import BranchCount from '@/components/enterprise-details/branch-count' // 分支机构
import CredentialCount from '@/components/enterprise-details/credential-count' // 资质证书
import HonorImageCount from '@/components/enterprise-details/honor-image-count' // 荣誉信息
import HonorTaxCount from '@/components/enterprise-details/honor-tax-count' // 税务荣誉
import RegisteredPersonnelCount from '@/components/enterprise-details/registered-personnel-count' // 技术人员
import CompanyPersonnelResultsCount from '@/components/enterprise-details/company-personnel-results-count' // 人员业绩
import AchievementCount from '@/components/enterprise-details/achievement-count' // 中标信息
import FilingCount from '@/components/enterprise-details/filing-count' // 备案信息
import ShiXinCount from '@/components/enterprise-details/shi-xin-count' // 失信信息
import ZhiZingCount from '@/components/enterprise-details/zhi-zing-count' // 被执行人
import ManageOperateexceptionCount from '@/components/enterprise-details/manage-operateexception-count' // 经营异常
import ProjectOperateexceptionCount from '@/components/enterprise-details/project-operateexception-count' // 工程建设违法
import AdministrativePenaltyCount from '@/components/enterprise-details/administrative-penalty-count' // 税务违法 重大税收违法
import AdministrativeReportCount from '@/components/enterprise-details/administrative-report-count' // 税务违法 欠税公告
import AdministrativeOtherCount from '@/components/enterprise-details/administrative-other-count' // 税务违法 欠税公告
import IllegalBidCount from '@/components/enterprise-details/illegal-bid-count' // 招投标违法 政府采购严重违法失信
import IllegalOtherCount from '@/components/enterprise-details/illegal-other-count' // 其他招投标违法
import OtherPunishmentCount from '@/components/enterprise-details/other-punishment-count' // 其他行政处罚
import TmCount from '@/components/enterprise-details/tm-count' // 商标信息
import PatentCount from '@/components/enterprise-details/patent-count' // 专利信息
import CopyrightCount from '@/components/enterprise-details/copyright-count' // 作品著作权
import SoftwarecopyrightCount from '@/components/enterprise-details/softwarecopyright-count' // 软著著作权
import InvestinfoCount from '@/components/enterprise-details/investinfo-count' // 对外投资
import LiquidationCount from '@/components/enterprise-details/liquidation-count' // 清算信息
import SpotcheckCount from '@/components/enterprise-details/spotcheck-count' // 抽查检查
import RecruitinfoCount from '@/components/enterprise-details/recruitinfo-count' // 招聘信息
import NewsinfoCount from '@/components/enterprise-details/newsinfo-count' // 新闻信息
import ImportExportCount from '@/components/enterprise-details/import-export-count' // 进出口信用
import LandPublishCount from '@/components/enterprise-details/land-publish-count' // 土地公示
import LandPurchaseCount from '@/components/enterprise-details/land-purchase-count' // 购地信息
import LandTransferCount from '@/components/enterprise-details/land-transfer-count' // 土地转让
import BondCount from '@/components/enterprise-details/bond-count' // 债券信息
import AnnualReportCount from '@/components/enterprise-details/annual-report-count' // 企业年报
import JudgementCount from '@/components/enterprise-details/judgement-count' // 法律诉讼
import CourtCount from '@/components/enterprise-details/court-count' // 法院公告
import PledgeCount from '@/components/enterprise-details/pledge-count' // 股权出质
import SimplecancellationCount from '@/components/enterprise-details/simplecancellation-count' // 简易注销
import LandMortgageCount from '@/components/enterprise-details/land-mortgage-count' // 土地抵押
// import PublishNoticeCount from '@/components/enterprise-details/publish-notice-count' // 公示催告
import MovablespledgeCount from '@/components/enterprise-details/movablespledge-count' // 动产抵押
import CourtnoticeCount from '@/components/enterprise-details/courtnotice-count' // 开庭公告
import DeliveryNoticeCount from '@/components/enterprise-details/delivery-notice-count' // 送达公告
import RegisterCount from '@/components/enterprise-details/register-count' // 立案信息
import AssistanceCount from '@/components/enterprise-details/assistance-count' // 司法协助
import InfoCount from '@/components/enterprise-details/info-count' // 基本信息
import ToptenholderCount from '@/components/enterprise-details/toptenholder-count' // 十大股东
// import CoreThemeCount from '@/components/enterprise-details/core-theme-count' // 核心题材
import ImportantDwdbCount from '@/components/enterprise-details/important-dwdb-count' // 对外担保
import ImportantWgclCount from '@/components/enterprise-details/important-wgcl-count' // 违规处理
import ProspectusCount from '@/components/enterprise-details/prospectus-count' // 招股书
import MainIndicatorCount from '@/components/enterprise-details/main-indicator-count' // 主要指标
import InfoIssueCount from '@/components/enterprise-details/info-issue-count' // 发行相关
import IncomeStatementCount from '@/components/enterprise-details/income-statement-count' // 利润表
import BalanceSheetCount from '@/components/enterprise-details/balance-sheet-count' // 资产负债
import CashFlowStatementCount from '@/components/enterprise-details/cash-flow-statement-count' // 现金流量
import { forwardBG } from '@/api/forward.js'
import { ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'
import {
  refreshToken,
  bhAccountInfo
} from '@/api/user.js'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'enterprise-details',
  components: {
    EnterpriseDetailsTitle,
    PartnerCount,
    EmployeeCount,
    ChangeCount,
    BranchCount,
    CredentialCount,
    HonorImageCount,
    HonorTaxCount,
    RegisteredPersonnelCount,
    CompanyPersonnelResultsCount,
    AchievementCount,
    FilingCount,
    ZhiZingCount,
    ShiXinCount,
    ManageOperateexceptionCount,
    ProjectOperateexceptionCount,
    AdministrativePenaltyCount,
    AdministrativeReportCount,
    AdministrativeOtherCount,
    IllegalBidCount,
    IllegalOtherCount,
    OtherPunishmentCount,
    TmCount,
    PatentCount,
    CopyrightCount,
    SoftwarecopyrightCount,
    InvestinfoCount,
    LiquidationCount,
    SpotcheckCount,
    RecruitinfoCount,
    NewsinfoCount,
    ImportExportCount,
    LandPublishCount,
    LandPurchaseCount,
    LandTransferCount,
    BondCount,
    AnnualReportCount,
    JudgementCount,
    CourtCount,
    SimplecancellationCount,
    LandMortgageCount,
    // PublishNoticeCount,
    MovablespledgeCount,
    CourtnoticeCount,
    DeliveryNoticeCount,
    AssistanceCount,
    RegisterCount,
    PledgeCount,
    InfoCount,
    ToptenholderCount,
    // CoreThemeCount,
    ImportantDwdbCount,
    ImportantWgclCount,
    ProspectusCount,
    MainIndicatorCount,
    InfoIssueCount,
    IncomeStatementCount,
    BalanceSheetCount,
    CashFlowStatementCount
  },
  data () {
    return {
      visible: false,
      btnActive: '9',
      companyId: '',
      companyCode: '',
      showArrow: false,
      companyDetail: {},
      navList: [
        {
          name: '基本信息',
          value: 'oneList',
          num: '0'
        },
        {
          name: '行业信息',
          value: 'twoList',
          num: '0'
        },
        {
          name: '信用信息',
          value: 'threeList',
          num: '0'
        },
        {
          name: '知识产权',
          value: 'fourList',
          num: '0'
        },
        {
          name: '经营状况',
          value: 'fiveList',
          num: '0'
        },
        {
          name: '经营风险',
          value: 'sixList',
          num: '0'
        },
        {
          name: '上市信息',
          value: 'sevenList',
          num: '0'
        }
      ],
      oneList: [
        {
          name: '工商信息',
          value: 'gs'
        },
        {
          name: '股东信息',
          num: '0',
          value: 'partnerCount'
        },
        {
          name: '主要人员',
          num: '0',
          value: 'employeeCount'
        },
        {
          name: '变更记录',
          num: '0',
          value: 'changeCount'
        },
        {
          name: '分支机构',
          num: '0',
          value: 'branchCount'
        }
      ],
      twoList: [
        {
          name: '资质证书',
          num: '0',
          value: 'credentialCount'
        },
        {
          name: '荣誉信息',
          num: '0',
          value: 'honorImageCount'
        },
        {
          name: '技术人员',
          num: '0',
          value: 'registeredPersonnelCount'
        },
        {
          name: '人员业绩',
          num: '0',
          value: 'companyPersonnelResultsCount'
        },
        {
          name: '中标信息',
          num: '0',
          value: 'achievementCount'
        },
        {
          name: '履约行为',
          num: '0',
          value: 'changeRecod'
        },
        {
          name: '备案信息',
          num: '0',
          value: 'filingCount'
        }
      ],
      threeList: [
        {
          name: '失信信息',
          num: '0',
          value: 'shixinCount'
        },
        {
          name: '被执行人',
          num: '0',
          value: 'zhixingCount'
        },
        {
          name: '经营异常',
          num: '0',
          value: 'manageOperateexceptionCount'
        },
        {
          name: '工程建设违法',
          num: '0',
          value: 'projectOperateexceptionCount'
        },
        {
          name: '税务违法',
          num: '0',
          value: 'administrativePenaltyCount'
        },
        {
          name: '招投标违法',
          num: '0',
          value: 'illegalBidCount'
        },
        {
          name: '其他行政处罚',
          num: '0',
          value: 'otherPunishmentCount'
        }
      ],
      fourList: [
        {
          name: '商标信息',
          num: '0',
          value: 'tmCount'
        },
        {
          name: '专利信息',
          num: '0',
          value: 'patentCount'
        },
        {
          name: '作品著作权',
          num: '0',
          value: 'copyrightCount'
        },
        {
          name: '软件著作权',
          num: '0',
          value: 'softwarecopyrightCount'
        }
      ],
      fiveList: [
        {
          name: '对外投资',
          num: '0',
          value: 'investinfoCount'
        },
        {
          name: '股权出质',
          num: '0',
          value: 'pledgeCount'
        },
        {
          name: '清算信息',
          num: '0',
          value: 'liquidationCount'
        },
        {
          name: '抽查检查',
          num: '0',
          value: 'spotcheckCount'
        },
        {
          name: '招聘信息',
          num: '0',
          value: 'recruitinfoCount'
        },
        {
          name: '新闻信息',
          num: '0',
          value: 'newsinfoCount'
        },
        {
          name: '进出口信用',
          num: '0',
          value: 'importExportCount'
        },
        {
          name: '地块公示',
          num: '0',
          value: 'landPublishCount'
        },
        {
          name: '购地信息',
          num: '0',
          value: 'landPurchaseCount'
        },
        {
          name: '土地转让',
          num: '0',
          value: 'landTransferCount'
        },
        {
          name: '债券信息',
          num: '0',
          value: 'bondCount'
        },
        {
          name: '企业年报',
          num: '0',
          value: 'annualReportCount'
        }
      ],
      sixList: [
        {
          name: '法律诉讼',
          num: '0',
          value: 'judgementCount'
        },
        {
          name: '法院公告',
          num: '0',
          value: 'courtCount'
        },
        {
          name: '简易注销',
          num: '0',
          value: 'simplecancellationCount'
        },
        {
          name: '土地抵押',
          num: '0',
          value: 'landMortgageCount'
        },
        // {
        //   name: '公示催告',
        //   num: '0',
        //   value: 'publishNoticeCount'
        // },
        {
          name: '动产抵押',
          num: '0',
          value: 'movablespledgeCount'
        },
        {
          name: '开庭公告',
          num: '0',
          value: 'courtnoticeCount'
        },
        {
          name: '送达公告',
          num: '0',
          value: 'deliveryNoticeCount'
        },
        {
          name: '立案信息',
          num: '0',
          value: 'registerCount'
        },
        {
          name: '司法协助',
          num: '0',
          value: 'assistanceCount'
        }
      ],
      sevenList: [
        {
          name: '基本信息',
          num: '0',
          value: 'infoCount'
        },
        {
          name: '十大股东',
          num: '0',
          value: 'toptenholderCount'
        },
        // {
        // name: '核心题材',
        // num: '0',
        // value: 'coreThemeCount'
        // },
        {
          name: '对外担保',
          num: '0',
          value: 'importantDwdbCount'
        },
        {
          name: '违规处理',
          num: '0',
          value: 'importantWgclCount'
        },
        {
          name: '招股书',
          num: '0',
          value: 'prospectusCount'
        },
        {
          name: '主要指标',
          num: '0',
          value: 'mainIndicatorCount'
        },
        {
          name: '发行相关',
          num: '0',
          value: 'infoIssueCount'
        },
        {
          name: '利润表',
          num: '0',
          value: 'incomeStatementCount'
        },
        {
          name: '资产负债',
          num: '0',
          value: 'balanceSheetCount'
        },
        {
          name: '现金流量',
          num: '0',
          value: 'cashFlowStatementCount'
        }
      ]
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'busId'
    ])
  },
  mounted () {
    const vm = this
    setInterval(function () {
      if (vm.idToken) {
        refreshToken({}).then(result => {
          if (result.code === 0) {
            const idToken = result?.data?.data?.refreshIdToken
            if (idToken) {
              vm.$store.dispatch('setIdToken', idToken)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    }, 10 * 60 * 1000)
  },
  created () {
    const vm = this
    vm.companyId = vm.busId
    if (vm.idToken) {
      bhAccountInfo().then(result => {
        if (result.code === 0) {
          if (vm.companyId) {
            vm.initNum()
            vm.initNumTwo()
            vm.initNumThree()
          }
        } else {
          vm.companyId = ''
          ElMessage.error({ message: '身份已过期！', offset: 60, duration: 0 })
        }
      })
        .catch(error => {
          console.log(error)
        })
    } else {
      ElMessage.error({ message: '身份已过期！', offset: 60, duration: 0 })
    }
  },
  methods: {
    returnTop (e, index) {
      this.visible = false
      this.btnActive = index
      document.querySelector('#' + e).scrollIntoView()
    },
    returnTopEmpty () {
    },
    returnTopNav (e, index, num) {
      const vm = this
      this.btnActive = index
      this.visible = false
      if (e === 'oneList') {
        document.querySelector('#gs').scrollIntoView()
      } else {
        if (num > 0) {
          for (let i = 0; i < vm[e].length; i++) {
            if (this[e][i].num > 0) {
              document.querySelector('#' + this[e][i].value).scrollIntoView()
              break
            }
          }
        } else {
          document.querySelector('#' + this[e][0].value).scrollIntoView()
        }
      }
    },
    // 获取数量
    async initNum () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyInfo/getCompanyCountFirst',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.oneList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arr.push(item)
          })
          vm.oneList = arr
          const arrs = []
          res.taxHonorImageCount = res.honorImageCount - res.industryHonorImageCount - res.commercialHonorImageCount
          vm.twoList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
              if (item.value === 'honorImageCount') {
                item.industryHonorImageCount = res.industryHonorImageCount
                item.commercialHonorImageCount = res.commercialHonorImageCount
                item.taxHonorImageCount = res.taxHonorImageCount
              }
            }
            arrs.push(item)
          })
          vm.twoList = arrs
        } else {
          ElMessage.error({ message: result.msg, offset: 60 })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取数量2
    async initNumTwo () {
      const vm = this
      const data = {
        // dateBackgroundUrl: '/companyInfo/getCompanyCountSecond',
        dateBackgroundUrl: '/companyInfo/getNewCompanyCountSecond',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.administrativePenaltyCount = res.greatCount + res.taxesCount + res.otherTaxesCount// 税务违法 +其他税务违法 otherTaxesCount
          res.illegalBidCount = res.illegalBidCount + res.otherBiddingCount // 招投标违法+其他招投标违法 otherBiddingCount
          // res.otherBiddingCount
          const arr = []
          vm.threeList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
              if (item.value === 'administrativePenaltyCount') {
                item.greatCount = res.greatCount
                item.taxesCount = res.taxesCount
                item.otherTaxesCount = res.otherTaxesCount
              }
              if (item.value === 'illegalBidCount') {
                item.illegalBidCount = res.illegalBidCount
                item.otherBiddingCount = res.otherBiddingCount
              }
            }
            arr.push(item)
          })
          vm.threeList = arr
          const arrs = []
          vm.fourList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arrs.push(item)
          })
          vm.fourList = arrs
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取数量3
    async initNumThree () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyInfo/getCompanyCountThird',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.fiveList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arr.push(item)
          })
          vm.fiveList = arr
          const arrs = []
          vm.sixList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arrs.push(item)
          })
          vm.sixList = arrs
          const arrx = []
          vm.sevenList.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arrx.push(item)
          })
          vm.sevenList = arrx
          console.log(vm.sevenList)
          console.log(res)
          // 获取企业基本工商信息
          vm.initCompanyDetail()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取企业基本工商信息
    async initCompanyDetail () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyInfo/companyDetail',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          if (res.regDate) {
            res.regDate = res.regDate.slice(0, 10)
          }
          vm.companyDetail = res
          vm.companyCode = res.companyCode
          if (vm.sevenList[5]?.num && vm.sevenList[5]?.num * 1 > 0) vm.$refs.mainIndicatorCountRef.initData(res.companyCode)
          if (vm.sevenList[4]?.num && vm.sevenList[4]?.num * 1 > 0) vm.$refs.prospectusCountRef.initData(res.companyCode)
          if (vm.sevenList[3]?.num && vm.sevenList[3]?.num * 1 > 0) vm.$refs.importantWgclCountRef.initData(res.companyCode)
          if (vm.sevenList[2]?.num && vm.sevenList[2]?.num * 1 > 0) vm.$refs.importantDwdbCountRef.initData(res.companyCode)
          // if (vm.sevenList[2]?.num && vm.sevenList[2]?.num * 1 > 0) vm.$refs.coreThemeCountRef.initData(res.companyCode)
          if (vm.sevenList[1]?.num && vm.sevenList[1]?.num * 1 > 0) vm.$refs.toptenholderCountRef.initData(res.companyCode)
          if (vm.sevenList[6]?.num && vm.sevenList[6]?.num * 1 > 0) vm.$refs.infoIssueCountRef.initData(res.companyCode)
          if (vm.sevenList[7]?.num && vm.sevenList[7]?.num * 1 > 0) vm.$refs.incomeStatementCountRef.initData(res.companyCode)
          if (vm.sevenList[8]?.num && vm.sevenList[8]?.num * 1 > 0) vm.$refs.balanceSheetCountRef.initData(res.companyCode)
          if (vm.sevenList[9]?.num && vm.sevenList[9]?.num * 1 > 0) vm.$refs.cashFlowStatementCountRef.initData(res.companyCode)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  watch: {
    oneList: {
      handler (newName, oldName) {
        let a = 0
        this.oneList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[0].num = a
      },
      deep: true
    },
    twoList: {
      handler (newName, oldName) {
        let a = 0
        this.twoList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[1].num = a
      },
      deep: true
    },
    threeList: {
      handler (newName, oldName) {
        let a = 0
        this.threeList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[2].num = a
      },
      deep: true
    },
    fourList: {
      handler (newName, oldName) {
        let a = 0
        this.fourList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[3].num = a
      },
      deep: true
    },
    fiveList: {
      handler (newName, oldName) {
        let a = 0
        this.fiveList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[4].num = a
      },
      deep: true
    },
    sixList: {
      handler (newName, oldName) {
        let a = 0
        this.sixList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[5].num = a
      },
      deep: true
    },
    sevenList: {
      handler (newName, oldName) {
        let a = 0
        this.sevenList.forEach(item => {
          if (item.num) {
            a += Number(item.num)
          }
        })
        this.navList[6].num = a
      },
      deep: true
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.nav-list-content {
  background: #FAFBFF;
  border: 1px solid #E6E6E6;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-radius: 4px 4px 0px 0px;
  // margin-top: 20px;
  .nav-list-content-li {
    padding: 0px 30px;
    font-size: 16px;
    color: #333;
    width: 200px;
    text-align: center;
    cursor: pointer;
  }
  .nav-list-content-li.active {
    color: #5D6FE9;
  }
}
.middle-content-div {
  padding: 20px;
  border-top: none;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  text-align: left;
  .middle-content-div-title {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.nav-list-content-list {
    padding: 0px 30px;
    font-size: 14px;
    color: #1A1A1A;
    text-align: center;
    width: 200px;
    .nav-list-content-list-div {
      padding: 10px 0;
      text-align: center;
      cursor: pointer;
      .font-color-div {
        color: #5D6FE9;
        margin-left: 5px;
      }
      .font-color-div.active {
        color: #999;
        cursor:not-allowed;
      }
    }
    .nav-list-content-list-div:hover {
      color: #5D6FE9;
      font-weight: bold;
    }
    .nav-list-content-list-div.active {
      color: #999;
      cursor:not-allowed;
    }
  }
/deep/ .el-descriptions__label.is-bordered-label {
  background-color: #E8EBFC !important;
}
.table-lsit th{
  background-color: #E8EBFC !important;
}
</style>
