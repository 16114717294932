<template>
  <!-- 失信信息 -->
  <div>
    <block>
      <div id="shixinCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>失信信息 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
            <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!fuzzyDataShow && !returnLimit"
          label="操作"
          width="80">
          <template #default="scope">
           <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <template #empty style="width: 100%;">
          <gaussian-blur-empty-login @loginEmit="loginEmit" v-show="!islogin"></gaussian-blur-empty-login>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="fuzzyDataShow && returnLimit"></gaussian-blur-empty-vip>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length == 0"></gaussian-blur-empty-vip>
        </template>
      </el-table>
      <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length > 0"></gaussian-blur-empty-vip>
      <div class="table-page" v-show="!fuzzyDataShow && !returnLimit">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
        <el-dialog title="失信被执行人详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
            <el-descriptions class="margin-top"  :column="2" :size="size" border>
              <el-descriptions-item :span="1">
                <template #label>失信被执行人</template>
                {{companyDetail.name || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>法定代表人</template>
                {{companyDetail.ownername || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>身份证号码／组织机构代码</template>
                {{companyDetail.orgno || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>执行依据文号</template>
                {{companyDetail.executeno || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>案号</template>
                {{companyDetail.anno || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>做出执行依据单位</template>
                {{companyDetail.executegov || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>执行法院</template>
                {{companyDetail.executeunite || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>被执行人的履行情况</template>
                {{companyDetail.executestatus || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>立案日期</template>
                {{companyDetail.liandate || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>发布日期</template>
                {{companyDetail.publicdate || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>已履行</template>
                {{companyDetail.performedpart || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>未履行</template>
                {{companyDetail.unperformpart || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>省份</template>
                {{companyDetail.province || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>失信被执行人行为具体情形</template>
                {{companyDetail.actionremark || '-'}}
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template #label>生效法律文书确定的义务</template>
                {{companyDetail.yiwu || '-'}}
              </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
      </div>
    </block>
  </div>
</template>

<script>
import { oldForwardBG } from '@/api/forward.js'
import GaussianBlurEmptyLogin from '@/components/gaussian-blur/gaussian-blur-empty-login'
import GaussianBlurEmptyVip from '@/components/gaussian-blur/gaussian-blur-empty-vip'
import { mapGetters } from 'vuex'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  components: { GaussianBlurEmptyLogin, GaussianBlurEmptyVip },
  name: 'shi-xin-count',
  props: { companyId: String, num: Number },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: this.num ? this.num : 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '立案时间',
        property: 'liandate'
      }, {
        label: '立案编号',
        property: 'anno'
      }, {
        label: '执行法院',
        property: 'executegov'
      }, {
        label: '失信行为',
        property: 'actionremark'
      }, {
        label: '履行情况',
        property: 'executestatus'
      }, {
        label: '发布日期',
        property: 'publicdate'
      }],
      TableData: [],
      islogin: false,
      returnLimit: false,
      fuzzyDataShow: false
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  mounted () {
    if (this.idToken) {
      this.islogin = true
      this.initData()
    } else {
      this.islogin = false
    }
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (val) {
          if (this.idToken) {
            this.islogin = true
            this.initData()
          } else {
            this.islogin = false
          }
        }
      }
    },
    vipLevelChange: function (newValue, oldValue) {
      if (this.idToken) {
        this.islogin = true
        this.initData()
      } else {
        this.islogin = false
      }
    }
  },
  methods: {
    handleTo (e) {
      this.dialogTableVisible = true
      this.companyDetail = e
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyCredit/getDiscreditList',
        dateBackgroundRequestType: 'get',
        data: {
          qcc_company_id: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10,
          history: 0
        }
      }
      oldForwardBG(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.liandate = item.liandate ? item.liandate.substring(0, 10) : '-'
            item.publicdate = item.publicdate ? item.publicdate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  width: 100%;
}
</style>
