<template>
  <div>
    <div class="company-title-content">
      <!-- 企业名称 -->
      <div class="dis-bet-cen title-content">
        <div>
          <span style="font-size: 20px;color: #333;">{{companyDetail.companyName || '-'}}</span>
          <span v-if="companyDetail.registerStat === '存续' || companyDetail.registerStat === '在业'||companyDetail.registerStat === '在营' || companyDetail.registerStat === '在营（开业）企业'||companyDetail.registerStat === '存续（在营、开业、在册）'||companyDetail.registerStat === '开业'||companyDetail.registerStat === '正常'||companyDetail.registerStat === ' 迁入'||companyDetail.registerStat === '迁出'" class="tag-green">{{companyDetail.registerStat }}</span>
          <span v-else-if="companyDetail.registerStat === '注销'||companyDetail.registerStat === '吊销，已注销'||companyDetail.registerStat === '吊销，未注销'||companyDetail.registerStat === '吊销企业'||companyDetail.registerStat === '已吊销'||companyDetail.registerStat === '异常'||companyDetail.registerStat === '注销企业'" class="tag-red">{{companyDetail.registerStat }}</span>
          <span v-else-if="companyDetail.registerStat == null || companyDetail.registerStat == 'null' || companyDetail.registerStat == ''" class="tag-grey">暂无</span>
          <span v-else class="tag-green">{{companyDetail.registerStat }}</span>
          <!-- <span class="tag-yellow" style="cursor: pointer;" @click="isAuthentication ? '' : handleToAuthentication()">{{isAuthentication ? '已认证' : '我要认证'}}</span> -->
        </div>
        <!-- <div>
          <span class="tag-grey-btn btn-hover" @click="handleReport">
            <span class="icon iconfont" style="font-size: 12px;margin-right: 5px;">&#xe826;</span>企业报告
          </span>
          <el-popover placement="bottom" :width="200" trigger="hover">
            <template #reference>
              <span class="tag-grey-btn" :class="follow ? 'activeFont' : 'btn-hover'" @click="handleFollow()"><span class="icon iconfont"  style="font-size: 12px;margin-right: 5px;">{{follow ? '&#xe601;' : '&#xe615;'}}</span>{{follow ? '已关注' : '关注'}}</span>
            </template>
            <div style="text-align: center;">
              <div style="text-align: left;">关注微信公众号易工程平台实时接收企业最新中标报告</div>
              <el-image style="width: 100px; height: 100px" :src="publicUrl" fit="contain"></el-image>
            </div>
          </el-popover>
        </div> -->
      </div>
      <el-row :gutter="20">
        <el-col :span="20">
          <div class="bkd-color">
            <el-row style="margin-bottom: 15px;color: #999;font-size: 14px;">
              <el-col :span="12" >电话：{{companyDetail.userMobile || '-'}}</el-col>
              <el-col :span="12" >网站：<span style="color: #5D6FE9;cursor: pointer;" @click="handleToLink(companyDetail.website)">{{companyDetail.website || '-'}}</span></el-col>
            </el-row>
            <el-row style="margin-bottom: 15px;color: #999;font-size: 14px;">
              <el-col :span="12">邮箱：{{companyDetail.userEmail || '-'}}</el-col>
              <el-col :span="12">地址：{{companyDetail.regAddress || '-'}}</el-col>
            </el-row>
            <el-row style="color: #999;font-size: 14px;">
              <el-col :span="24" style="overflow: initial;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-popover
                  v-if="companyDetail.desShort"
                  placement="bottom-start"
                  title="简介："
                  :width="900"
                  trigger="hover"
                  :content="companyDetail.desShort"
                >
                  <template #reference>
                   <span>简介：{{companyDetail.desShort || '-'}}</span>
                  </template>
                </el-popover>
                <span v-else>简介：{{companyDetail.desShort || '-'}}</span>
              </el-col>
            </el-row>
          </div>
          <!-- title-num -->
          <div class="dis-bet-cen" style="margin-top: 10px;">
            <div class="dis-aro-cen type-num-content" v-for="(item, index) of list" :key="index">
              <el-image style="width: 44px; height: 44px" :src="item.url" fit="fit"></el-image>
              <div style="margin-left: 10px;text-align: center;cursor: pointer;" @click="returnToNav(item.num, item.value)">
                <div style="font-size: 14px;color: #666;">{{item.name}}</div>
                <div style="font-size: 20px;color: #5D6FE9;">{{item.num}}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col @click="companyPortraitClick" :span="4" class="bkd-color" style="text-align: center;cursor:pointer;">
          <el-image style="width: 90px; height: 104px" :src="imgUrl" fit="fit"></el-image>
          <div style="margin-top: 20px;font-size: 14px;color: #666666;cursor: pointer;">查看企业画像></div>
        </el-col>
      </el-row>
    </div>
    <div style="background: #FAFAFA;height: 40px;line-height: 40px;text-align: right;border: 1px solid #E6E6E6;border-top: none;">
      <!-- <span style="font-size: 24px;color: #5D6FE9;vertical-align: middle;" class="icon iconfont">&#xe648;</span> -->
      <!-- <span class="info-btn" @click="handleCorrection">信息纠错</span> -->
    </div>
    <el-dialog title="下载报告" width="60%" append-to-body="true" v-model="dialogTableVisible">
      <div class="dis-bet-cen" style="flex-wrap: wrap;margin-bottom: 15px;">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="report-content">
              <div class="report-img-content">
                <el-image :src="jcbg" style="width: 100px; height: 130px;" fit="contain"></el-image>
              </div>
              <div class="report-right-content" style="width: 100%;">
                <div>
                  <div style="font-size: 16px;color: #333;">基础版企业报告</div>
                  <div style="color: #999;font-size: 12px;margin-top: 5px;">快速了解企业基本信息情况</div>
                </div>
                <div class="dis-bet-cen" style="width: 100%;">
                  <span style="cursor: pointer;" @click="handleOpenPdf('3')">查看模板></span>
                  <span class="btn-report" @click="generateReportClick('00120001')">立即生成</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="report-content">
              <div class="report-img-content">
                <el-image :src="sjbg" style="width: 100px; height: 130px;" fit="contain"></el-image>
              </div>
              <div class="report-right-content">
                <div>
                  <div style="font-size: 16px;color: #333;">升级版企业报告</div>
                  <div style="color: #999;font-size: 12px;margin-top: 5px;">11大项及20小项数据维度，以可视化图表方式呈现同业 中最全面的信用分析报告</div>
                </div>
                <div class="dis-bet-cen" style="width: 100%;">
                  <span style="cursor: pointer;" @click="handleOpenPdf('2')">查看模板></span>
                  <span class="btn-report" @click="generateReportClick('00120002')">立即生成</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" style="margin-top: 15px;">
            <div class="report-content">
              <div class="report-img-content">
                <el-image :src="jrbg" style="width: 100px; height: 130px;" fit="contain"></el-image>
              </div>
              <div class="report-right-content">
                <div>
                  <div style="font-size: 16px;color: #333;">金融版企业报告</div>
                  <div style="color: #999;font-size: 12px;margin-top: 5px;">深度数据挖掘，以可视化图表方式呈现全方位的企业信 用报告信息</div>
                </div>
                <div class="dis-bet-cen" style="width: 100%;">
                  <span style="cursor: pointer;" @click="handleOpenPdf('1')">查看模板></span>
                  <span class="btn-report" @click="generateReportClick('00120003')">立即生成</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog title="信息纠错" append-to-body="true" v-model="dialogVisible" width="60%">
      <el-form :model="errorForm" :rules="errorRules" ref="errorForm" label-width="20%" label-position="right" style="margin-top: 0px" >
        <el-form-item label="公司名称：" prop="companyName">
          <el-input placeholder="请输入公司名称" readonly class="dis-sta-cen" style="width: 80%;" v-model="errorForm.companyName" ></el-input>
        </el-form-item>
        <el-form-item label="有误模块：" prop="radioBox">
          <div style="color: #999;">选择信息有误的内容，便于我们定位问题。</div>
          <el-radio-group v-model="errorForm.radioBox" size="mini" @change="handleChaneRadio">
            <el-radio-button v-for="(item, index) in navList" :label="item.value" :key="index">{{item.name}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="radioVal" v-if="errorForm.radioBox">
          <div style="display: flex;justify-content: flex-start;flex-wrap: wrap;width: 90%;">
            <el-radio-group v-model="errorForm.radioVal" size="mini">
              <el-radio @change="handleChangeRadioChick" size="small" v-model="errorForm.radioVal" v-for="(item, index) of radioList" :key="index" :label="item.name" border>{{item.name}}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="问题描述：" prop="describeText">
          <el-input placeholder="请详细描述您发现的问题，便于我们核实处理" show-word-limit maxlength="300" type="textarea" :autosize="{ minRows: 6 }" class="dis-sta-cen" style="width: 80%;" v-model="errorForm.describeText" ></el-input>
        </el-form-item>
        <!-- <el-form-item label="图片附件：" prop="fileUrl">
          <el-upload
            class="upload-demo"
            :before-upload="function (file) { return beforeAvatarUpload(file, 'errorForm')}"
            :on-preview="handlePreview"
            :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'errorForm')}"
            multiple
            :limit="vipLevelChange === '00010002' ? 200 : 9"
            :on-exceed="handleExceed"
            :file-list="errorForm.fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <template #tip>
              <div class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                <div>
                  <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</p>
                </div>
              </div>
            </template>
          </el-upload>
        </el-form-item> -->
        <!-- <el-form-item label="联系方式：" prop="phoneNum">
          <el-input placeholder="请输入联系方式,便于我们与您核实问题" class="dis-sta-cen" style="width: 80%;" v-model="errorForm.phoneNum" ></el-input>
        </el-form-item> -->
        <div class="dis-cen-cen">
          <el-button type="primary" :loading="errorSubmitLoading" @click="errorSubmit">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="温馨提示"
      v-model="generateReportDialog.show"
      append-to-body="true"
      width="30%">
      <span>{{ generateReportDialog.title }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="generateReportDialog = {show: false, obj: '', title: ''}">取 消</el-button>
          <el-button type="primary" @click="generateReportConfirm">立即生成</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="微信支付" width="60%" append-to-body="true" v-model="payDialogTableVisible">
      <el-row style="border-top: 1px solid #ebebeb;margin-bottom: 20px;padding-top: 20px;">
        <el-col :span="12" class="font-pay">{{ payData.title }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="font-pay">支付金额：<span style="font-size: 24px;color: #FC7A1E;">¥{{ payData.price }}</span></el-col>
      </el-row>
      <el-row style="margin: 20px 0;">
        <el-col :span="24" class="dis-aro-cen" style="background-color: #f5f5f9;">
          <div id="qrcode" ref="qrcode"></div>
          <!-- <el-image fit="contain" style="width: 300px;" draggable="false" :src="pay"></el-image> -->
          <el-image fit="contain" style="width: 300px;margin-right: 50px;" draggable="false" :src="pay2"></el-image>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 提交邮箱 -->
    <el-dialog title="生成报告" width="600px" append-to-body="true" v-model="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="邮箱" prop="email" label-width="80px">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <p style="color: red;margin-left: 20px;">*我们将尽快为您生成报告，报告将以邮件的形式发送给您，请注意查收。</p>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="form.email ? false : true" @click="handleSubEmail('form')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {
  creditBasicsReport,
  creditUpgradeReport,
  creditUpgradeReportFinance,
  createWebReportOrder,
  getReportPayState,
  offline,
  getPayCount
} from '@/api/report.js'
import {
  forwardBG,
  forwardFile
} from '@/api/forward.js'
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  getFoucsByUserIdAndCompanyIds,
  InsertUserFoucs,
  DelByUserIdAndCompanyId
} from '@/api/general-query/company'
import {
  formTrim
} from '@/utils/index' // 工具类
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'enterprise-details-title',
  props: { companyId: String },
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      reportType: '',
      dialogFormVisible: false,
      form: {
        email: ''
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      },
      publicUrl: require('../../assets/index/g.png'),
      errorForm: {
        companyId: '',
        companyName: '',
        userId: '',
        userName: '',
        userMobile: '',
        describeText: '',
        fileList: [],
        fileUrl: '',
        phoneNum: '',
        radioBox: '',
        radioVal: ''
      },
      radioList: [],
      navList: [
        {
          name: '基本信息',
          value: 'oneList'
        },
        {
          name: '行业信息',
          value: 'twoList'
        },
        {
          name: '信用信息',
          value: 'threeList'
        },
        {
          name: '知识产权',
          value: 'fourList'
        },
        {
          name: '经营状况',
          value: 'fiveList'
        },
        {
          name: '经营风险',
          value: 'sixList'
        },
        {
          name: '上市信息',
          value: 'sevenList'
        }
      ],
      errorSubmitLoading: false,
      dialogVisible: false,
      dialogTableVisible: false,
      islogin: false,
      isAuthentication: false,
      follow: false,
      companyDetail: {},
      jcbg: require('@/assets/report/jcbg.jpg'),
      sjbg: require('@/assets/report/sjbg.jpg'),
      jrbg: require('@/assets/report/jrbg.jpg'),
      list: [
        {
          value: 'credentialCount',
          name: '企业资质数量',
          num: '0',
          url: require('@/assets/report/zz.png')
        },
        {
          value: 'achievementCount',
          name: '中标业绩数量',
          num: '0',
          url: require('@/assets/report/zb.png')
        },
        {
          value: 'registeredPersonnelCount',
          name: '技术人员数量',
          num: '0',
          url: require('@/assets/report/people.png')
        },
        {
          value: 'filingCount',
          name: '备案信息数量',
          num: '0',
          url: require('@/assets/report/ba.png')
        },
        {
          value: 'honorImageCount',
          name: '荣誉信息数量',
          num: '0',
          url: require('@/assets/report/honor.png')
        }
      ],
      imgUrl: require('@/assets/report/ld.png'),
      errorRules: {
        describeText: [
          { required: true, message: '请输入问题描述', trigger: 'change' },
          { min: 1, max: 300, message: '长度在 1 到 300 个字符', trigger: 'change' }
        ],
        phoneNum: [
          { required: false, message: '请输入联系方式', trigger: 'change' },
          { min: 0, max: 16, message: '长度在 1 到 16 个字符', trigger: 'change' },
          { validator: checkPhone, trigger: 'change' }
        ],
        radioBox: [
          { required: true, message: '请选择问题模块', trigger: 'change' }
        ],
        radioVal: [
          { required: true, message: '请选择问题模块子分类', trigger: 'change' }
        ]
      },
      loading: '',
      generateReportDialog: {
        show: false,
        obj: '',
        title: ''
      },
      payDialogTableVisible: false,
      payData: {
        price: '',
        title: ''
      },
      pay2: require('@/assets/vip/pay2.png'),
      timers: '',
      orderNum: '',
      oneList: [
        {
          name: '工商信息',
          value: 'gs'
        },
        {
          name: '股东信息',
          num: '0',
          value: 'partnerCount'
        },
        {
          name: '主要人员',
          num: '0',
          value: 'employeeCount'
        },
        {
          name: '变更记录',
          num: '0',
          value: 'changeCount'
        },
        {
          name: '分支机构',
          num: '0',
          value: 'branchCount'
        }
      ],
      twoList: [
        {
          name: '资质证书',
          num: '0',
          value: 'credentialCount'
        },
        {
          name: '荣誉信息',
          num: '0',
          value: 'honorImageCount'
        },
        {
          name: '技术人员',
          num: '0',
          value: 'registeredPersonnelCount'
        },
        {
          name: '人员业绩',
          num: '0',
          value: 'companyPersonnelResultsCount'
        },
        {
          name: '中标信息',
          num: '0',
          value: 'achievementCount'
        },
        {
          name: '履约行为',
          num: '0',
          value: 'changeRecod'
        },
        {
          name: '备案信息',
          num: '0',
          value: 'filingCount'
        }
      ],
      threeList: [
        {
          name: '失信信息',
          num: '0',
          value: 'shixinCount'
        },
        {
          name: '被执行人',
          num: '0',
          value: 'zhixingCount'
        },
        {
          name: '经营异常',
          num: '0',
          value: 'manageOperateexceptionCount'
        },
        {
          name: '工程建设违法',
          num: '0',
          value: 'projectOperateexceptionCount'
        },
        {
          name: '税务违法',
          num: '0',
          value: 'administrativePenaltyCount'
        },
        {
          name: '招投标违法',
          num: '0',
          value: 'illegalBidCount'
        },
        {
          name: '其他行政处罚',
          num: '0',
          value: 'otherPunishmentCount'
        }
      ],
      fourList: [
        {
          name: '商标信息',
          num: '0',
          value: 'tmCount'
        },
        {
          name: '专利信息',
          num: '0',
          value: 'patentCount'
        },
        {
          name: '作品著作权',
          num: '0',
          value: 'copyrightCount'
        },
        {
          name: '软件著作权',
          num: '0',
          value: 'softwarecopyrightCount'
        }
      ],
      fiveList: [
        {
          name: '对外投资',
          num: '0',
          value: 'investinfoCount'
        },
        {
          name: '股权出质',
          num: '0',
          value: 'pledgeCount'
        },
        {
          name: '清算信息',
          num: '0',
          value: 'liquidationCount'
        },
        {
          name: '抽查检查',
          num: '0',
          value: 'spotcheckCount'
        },
        {
          name: '招聘信息',
          num: '0',
          value: 'recruitinfoCount'
        },
        {
          name: '新闻信息',
          num: '0',
          value: 'newsinfoCount'
        },
        {
          name: '进出口信用',
          num: '0',
          value: 'importExportCount'
        },
        {
          name: '地块公示',
          num: '0',
          value: 'landPublishCount'
        },
        {
          name: '购地信息',
          num: '0',
          value: 'landPurchaseCount'
        },
        {
          name: '土地转让',
          num: '0',
          value: 'landTransferCount'
        },
        {
          name: '债券信息',
          num: '0',
          value: 'bondCount'
        },
        {
          name: '企业年报',
          num: '0',
          value: 'annualReportCount'
        }
      ],
      sixList: [
        {
          name: '法律诉讼',
          num: '0',
          value: 'judgementCount'
        },
        {
          name: '法院公告',
          num: '0',
          value: 'courtCount'
        },
        {
          name: '简易注销',
          num: '0',
          value: 'simplecancellationCount'
        },
        {
          name: '土地抵押',
          num: '0',
          value: 'landMortgageCount'
        },
        {
          name: '公示催告',
          num: '0',
          value: 'publishNoticeCount'
        },
        {
          name: '动产抵押',
          num: '0',
          value: 'movablespledgeCount'
        },
        {
          name: '开庭公告',
          num: '0',
          value: 'courtnoticeCount'
        },
        {
          name: '送达公告',
          num: '0',
          value: 'deliveryNoticeCount'
        },
        {
          name: '立案信息',
          num: '0',
          value: 'registerCount'
        },
        {
          name: '司法协助',
          num: '0',
          value: 'assistanceCount'
        }
      ],
      sevenList: [
        {
          name: '基本信息',
          num: '0',
          value: 'infoCount'
        },
        {
          name: '十大股东',
          num: '0',
          value: 'toptenholderCount'
        },
        {
          name: '核心题材',
          num: '0',
          value: 'coreThemeCount'
        },
        {
          name: '对外担保',
          num: '0',
          value: 'importantDwdbCount'
        },
        {
          name: '违规处理',
          num: '0',
          value: 'importantWgclCount'
        },
        {
          name: '招股书',
          num: '0',
          value: 'prospectusCount'
        },
        {
          name: '主要指标',
          num: '0',
          value: 'mainIndicatorCount'
        },
        {
          name: '发行相关',
          num: '0',
          value: 'infoIssueCount'
        },
        {
          name: '利润表',
          num: '0',
          value: 'incomeStatementCount'
        },
        {
          name: '资产负债',
          num: '0',
          value: 'balanceSheetCount'
        },
        {
          name: '现金流量',
          num: '0',
          value: 'cashFlowStatementCount'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  watch: {
    dialogTableVisible: {
      handler (val, oldVal) {
        if (val === false) {
          this.btnStatus = false
          if (this.timers) {
            clearInterval(this.timers)
            this.timers = ''
          }
        }
      }
    },
    idToken: {
      handle (val, oldval) {
        if (this.idToken) {
          this.islogin = true
        } else {
          this.islogin = false
        }
        this.init()
        this.initNum()
      }
    },
    vipLevelChange: function (newValue, oldValue) {
      if (this.idToken) {
        this.islogin = true
      } else {
        this.islogin = false
      }
      if (this.userInfo) {
        this.userInfoMes = JSON.parse(this.userInfo)
        if (this.userInfoMes.businessInformationStatus === '0') {
          this.isAuthentication = false
        } else {
          if (this.userInfoMes.companyName === this.companyDetail.companyName) {
            this.isAuthentication = true
          }
        }
      }
      this.init()
      this.initNum()
    }
  },
  mounted () {
    if (this.idToken) {
      this.islogin = true
    } else {
      this.islogin = false
    }
    if (this.userInfo) {
      this.userInfoMes = JSON.parse(this.userInfo)
      if (this.userInfoMes.businessInformationStatus === '0') {
        this.isAuthentication = false
      } else {
        if (this.userInfoMes.companyName === this.companyDetail.companyName) {
          this.isAuthentication = true
        }
      }
    }
    if (this.companyId) {
      this.init()
      this.initNum()
    }
  },
  methods: {
    returnToNav (num, name) {
      if (num > 0) {
        document.querySelector('#' + name).scrollIntoView()
      }
    },
    handleChangeRadioChick () {
    },
    handleChaneRadio () {
      this.radioList = this[this.errorForm.radioBox]
      this.errorForm.radioVal = ''
    },
    // 打开pdf
    handleOpenPdf: function (e) {
      if (e === '1') {
        const url = '/finance.pdf'
        window.open(url, '_blank')
      } else if (e === '2') {
        const url = '/upgrade.pdf'
        window.open(url)
      } else {
        const url = '/base.pdf'
        window.open(url)
      }
    },
    // 信息纠错
    handleCorrection () {
      const vm = this
      if (this.idToken) {
        const thisUserInfo = JSON.parse(vm.userInfo)
        if (vm.$refs.errorForm) vm.$refs.errorForm.resetFields()
        vm.errorForm = {
          companyName: vm.companyDetail.companyName,
          companyId: vm.companyDetail.id,
          userId: thisUserInfo.userId,
          userName: thisUserInfo.loginName,
          userMobile: thisUserInfo.mobile,
          describeText: '',
          fileList: [],
          fileUrl: '',
          phoneNum: '',
          radioBox: '',
          radioVal: ''
        }
        vm.dialogVisible = true
      } else {
        vm.loginEmit()
      }
    },
    // 跳转去认证
    handleToAuthentication () {
      this.$router.push({
        name: 'data-correction'
      })
    },
    handleToLink (e) {
      window.open(e)
    },
    // 取消或者关注
    // 点击了关注
    handleFollow () {
      const vm = this
      if (vm.idToken && vm.companyDetail.qcc_company_id) {
        const data = {
          userId: vm.userInfoMes.userId,
          companyId: vm.companyDetail.qcc_company_id
        }
        if (vm.follow === true) { // 已经关注了 去取消
          DelByUserIdAndCompanyId(data).then(result => {
            if (result.code === 0) {
              // vm.isFocuse()
              ElMessage.success({ message: '操作成功！', offset: 60 })
            }
          }).catch(error => {
            console.log(error)
          })
        } else if (vm.follow === false) { // 没关注 去关注
          InsertUserFoucs(data).then(result => {
            if (result.code === 0) {
              // vm.isFocuse()
              ElMessage.success({ message: '操作成功！', offset: 60 })
            }
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        this.loginEmit()
        console.log('没登录去登录')
      }
    },
    // 报告下载
    handleReport () {
      this.dialogTableVisible = true
    },
    // 是否关注了公示
    isFocuse () {
      const vm = this
      const data = {
        userId: vm.userInfoMes.userId,
        companyIds: vm.companyDetail.qcc_company_id
      }
      getFoucsByUserIdAndCompanyIds(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          if (res.length > 0) {
            vm.follow = true
          } else {
            vm.follow = false
          }
        }
      })
    },
    init () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyInfo/companyDetail',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          if (res.regDate) {
            res.regDate = res.regDate.slice(0, 10)
          }
          vm.companyDetail = res
          // this.isFocuse()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取数量
    initNum: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyInfo/getCompanyCountFirst',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arrs = []
          vm.list.forEach(item => {
            for (const i in res) {
              if (item.value === i) {
                item.num = res[i]
              }
            }
            arrs.push(item)
          })
          vm.list = arrs
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 企业画像点击
    companyPortraitClick () {
      const vm = this
      let vipLevel = null
      if (this.vipData) {
        const thisVipData = JSON.parse(this.vipData)
        vipLevel = thisVipData?.vipLevel
      }
      if (vm.idToken && vipLevel === '00010002') {
        vm.$store.dispatch('setBusId', vm.companyId)
        const routeData = vm.$router.resolve({
          name: 'corporate-mes'
        })
        window.open(routeData.href, '_blank')
      } else if (vm.idToken) {
        ElMessage.info({ message: '开通VIP后可查看', offset: 20 })
      } else {
        vm.loginEmit()
      }
    },
    beforeAvatarUpload (file, obj) {
      const vm = this
      // 提示内容
      let fileManage = ''
      if (obj === 'errorForm') fileManage = '图片附件'
      // 文件重命名
      const oldFileName = file.name
      const oldFileNameArr = oldFileName.split('.')
      const newFileName = new Date().getTime() + '' + Math.ceil(Math.random() * 1000) + '.' + oldFileNameArr[oldFileNameArr.length - 1]
      const newFile = new File([file], newFileName, { // 文件不能直接重命名， 要重新new 一个file
        type: file.type
      })
      let isJPGMessage = ''
      const isJPG = newFile.type.toLowerCase() === 'image/jpeg' ||
        newFile.type.toLowerCase() === 'image/jpg' ||
        newFile.type.toLowerCase() === 'image/png'
      isJPGMessage = ' JPG PNG '
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        ElMessage.warning({
          message: '上传' + fileManage + '只能是' + isJPGMessage + '格式!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (!isLt2M) {
        ElMessage.warning({
          message: '上传' + fileManage + '大小不能超过 5MB!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (isJPG && isLt2M) {
        // 可以上传
        vm.loading = vm.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const form = new FormData()
        form.append('file', newFile)
        forwardFile(form).then(result => {
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
          if (result.code === 0) {
            const fileUrl = result.data?.downPath
            if (!fileUrl) {
              ElMessage.error(fileManage + '上传失败')
            } else {
              if (obj === 'errorForm') {
                // 资质
                if (vm.errorForm.fileUrl) {
                  vm.errorForm.fileUrl += ',' + fileUrl
                } else {
                  vm.errorForm.fileUrl = fileUrl
                }
                vm.errorForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                // vm.$refs.errorForm.clearValidate('fileUrl')
              }
            }
          } else {
            ElMessage.error(fileManage + '上传失败')
          }
        }).catch(function () {
          ElMessage.error(fileManage + '上传失败')
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
        })
        return false
      } else {
        return isJPG && isLt2M
      }
    },
    handleRemove (file, fileList, obj) {
      const vm = this
      let fileUrl = ''
      for (let i = 0; i < fileList.length; i++) {
        if (i === 0) {
          fileUrl += fileList[i].url
        } else {
          fileUrl += ',' + fileList[i].url
        }
      }
      if (obj === 'errorForm') {
        vm.errorForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.errorForm.fileUrl = fileUrl
      }
    },
    handleExceed (files, fileList) {
      return ElMessage.warning({
        message: '当前限制选择 9 个文件，本次选择了 ' + files.length + ' 个文件，共选择了 ' + (files.length + fileList.length) + ' 个文件',
        type: 'warning'
      })
    },
    handlePreview (file) {
      this.$viewerApi({
        images: [file.url]
      })
    },
    errorSubmit () {
      const vm = this
      vm.errorForm = formTrim(vm.errorForm)
      vm.$refs.errorForm.validate((valid) => {
        if (valid) {
          vm.errorSubmitLoading = true
          let radioVal = ''
          vm.navList.forEach(item => {
            if (item.value === vm.errorForm.radioBox) {
              radioVal = item.name
            }
          })
          const data = {
            dateBackgroundUrl: '/tCompanyRectify/insertSelective',
            dateBackgroundRequestType: 'post',
            data: {
              companyId: vm.errorForm.companyId,
              companyName: vm.errorForm.companyName,
              userId: vm.errorForm.userId,
              userName: vm.errorForm.userName,
              userMobile: vm.errorForm.userMobile,
              describeText: vm.errorForm.describeText,
              fileUrl: vm.errorForm.fileList.toString(),
              phoneNum: vm.errorForm.userMobile,
              errorModule: radioVal + '=>' + vm.errorForm.radioVal
            }
          }
          forwardBG(data).then(result => {
            if (result.code === 0) {
              this.dialogVisible = false
              vm.errorSubmitLoading = false
              ElMessage.success({ message: '提交成功！', offset: 60 })
            }
          }).catch(error => {
            vm.errorSubmitLoading = false
            console.log(error)
          })
        }
      })
    },
    // 点击了立即生成 报告
    generateReportClick (obj) {
      const vm = this
      if (!vm.userInfoMes?.userId) {
        return vm.loginEmit()
      }
      let title = ''
      title += '您正在生成《' + vm.companyDetail.companyName + '》'
      if (obj === '00120001') title += '普通版企业报告'
      if (obj === '00120002') title += '升级版企业报告'
      if (obj === '00120003') title += '金融版企业报告'
      title += '，是否确认生成？'
      vm.generateReportDialog = {
        show: true,
        obj: obj,
        title: title
      }
    },
    generateReportConfirm () {
      const vm = this
      let jump = true
      const obj = vm.generateReportDialog.obj
      const title = vm.generateReportDialog.title
      // 关闭弹窗
      // vm.generateReportDialog = {
      //   show: false,
      //   obj: '',
      //   title: ''
      // }
      vm.generateReportDialog.show = false
      // 开启加载框 防止重复点击
      vm.loading = vm.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 升级版报告和金融版下线-------------------------------
      if (obj === '00120002' || obj === '00120003') {
        vm.reportType = obj
        getPayCount({
          type: obj === '00120002' ? '升级版企业报告' : '金融版企业报告'
        }).then(result => {
          if (result.code === 0) {
            vm.dialogFormVisible = true
            vm.loading.close()
            vm.loading = ''
          } else if (result.code === 1 || result.code === 98) {
            return ElMessage.warning({
              message: result.msg,
              type: 'warning',
              offset: 60
            })
          } else if (result.code === 99) {
            vm.loading.close()
            vm.loading = ''
            jump = false
            vm.payData.price = result.data
            vm.payData.title = title
            // 发起支付
            const reportOrderData = {
              reoprtType: obj,
              // openid: "oW9fm5fT3uJ9VDvobtV4gU1nKH0w",
              userId: vm.userInfoMes.userId,
              contactInfo: vm.userInfoMes.mobile,
              customerName: vm.userInfoMes.realName ? vm.userInfoMes.mobile : null,
              orderInfo: vm.companyDetail.companyName,
              orderPrice: 160,
              // menuUrl: "/wxApi/creditReport/getReport/00120002",
              body: '企业信用报告',
              price: 16000
            }
            createWebReportOrder(reportOrderData).then(result => {
              if (result.code === 0) {
                vm.payDialogTableVisible = true
                const res = result.data
                vm.orderNum = res.orderNum
                if (res) {
                  vm.$nextTick(function () {
                    vm.qrcode(res)
                  })
                }
              }
            }).catch(error => {
              console.log(error)
            })
          }
        })
        return
      }
      // 升级版报告和金融版下线-------------------------------
      let requestMethod = null
      if (obj === '00120001') requestMethod = creditBasicsReport
      if (obj === '00120002') requestMethod = creditUpgradeReport
      if (obj === '00120003') requestMethod = creditUpgradeReportFinance
      const data = {
        userId: vm.userInfoMes.userId,
        companyId: vm.companyId,
        loading: new Date(), // 为了解决多次调用会等待每一次结果在调用下一次
        orderNo: null
      }
      requestMethod(data).then(result => {
        console.log(JSON.stringify(data))
        if (result.code === 0) {
        } else if (result.code === 1) {
          vm.loading.close()
          vm.loading = ''
          jump = false
          return ElMessage.warning({
            message: result.msg,
            type: 'warning',
            offset: 60
          })
        } else if (result.code === 98) { // 访问次数已受限
          vm.loading.close()
          vm.loading = ''
          jump = false
          return ElMessage.warning({
            message: result.msg,
            type: 'warning',
            offset: 60
          })
        } else if (result.code === 99) { // 访问次数已受限，可消费访问
          vm.loading.close()
          vm.loading = ''
          jump = false
          vm.payData.price = result.data
          vm.payData.title = title
          // 发起支付
          console.log('我要去消费了')
          const reportOrderData = {
            reoprtType: obj,
            // openid: "oW9fm5fT3uJ9VDvobtV4gU1nKH0w",
            userId: vm.userInfoMes.userId,
            contactInfo: vm.userInfoMes.mobile,
            customerName: vm.userInfoMes.realName ? vm.userInfoMes.mobile : null,
            orderInfo: vm.companyDetail.companyName,
            orderPrice: 160,
            // menuUrl: "/wxApi/creditReport/getReport/00120002",
            body: '企业信用报告',
            price: 16000
          }
          createWebReportOrder(reportOrderData).then(result => {
            console.log(result)
            if (result.code === 0) {
              vm.payDialogTableVisible = true
              const res = result.data
              vm.orderNum = res.orderNum
              console.log(res)
              if (res) {
                vm.$nextTick(function () {
                  vm.qrcode(res)
                })
              }
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }).catch(error => {
        console.log(error)
        if (error.message.indexOf('timeout') !== -1) {
        } else {
          ElMessage.error('报告生成失败')
        }
        if (vm.loading) vm.loading.close()
        vm.loading = ''
        jump = false
      })
      setTimeout(function () {
        if (jump) {
          vm.loading.close()
          vm.loading = ''
          vm.$router.push({
            name: 'report'
          })
        }
      }, 3000)
    },
    // 生成二维码
    qrcode (e) {
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 232, // 设置宽度
        height: 232, // 设置高度
        text: e.code_url
      })
      console.log(qrcode)
      this.getOrderStatus()
    },
    getOrderStatus () {
      const vm = this
      let num = 0
      vm.timers = setInterval(() => {
        num++
        console.log(num)
        if (num >= 500) {
          vm.payDialogTableVisible = false
          vm.dialogTableVisible = false
          if (vm.timers) {
            clearInterval(vm.timers)
            vm.timers = ''
          }
        }
        const data = {
          orderNum: vm.orderNum
        }
        getReportPayState(data).then(request => {
          console.log(request)
          if (request.code === 0) {
            vm.payDialogTableVisible = false
            // vm.dialogTableVisible = false
            if (vm.timers) {
              clearInterval(vm.timers)
              vm.timers = ''
            }
            ElMessage.success(request.msg)
            vm.generateReportConfirm()
            // vm.$router.push({
            //   name: 'report'
            // })
          }
        })
      }, 1500)
    },
    filterText (text) {
      if (!text) {
        return text
      }
      const reg = /<[^<>]+>/g
      return text.replace(reg, '')
    },
    // 提交邮箱
    handleSubEmail (formName) {
      const vm = this
      vm.$refs[formName].validate((valid) => {
        if (valid) {
          const emailData = {
            userEmail: vm.filterText(vm.form.email), // 邮箱地址
            userName: vm.userInfoMes.realName ? vm.userInfoMes.realName : vm.userInfoMes.mobile, // 用户名
            userTel: vm.userInfoMes.mobile, // 用户电话
            reportCompanyName: vm.filterText(vm.companyDetail.companyName), // 要生成那个企业的报告
            reportVersion: vm.reportType === '00120002' ? '升级版企业报告' : '金融版企业报告', // 要生成什么版本（升级版企业报告/金融版企业报告）
            source: 'PC端' // 来源（PC端/小程序端/H5端）
          }
          offline(emailData).then(result => {
            if (result.code === 0) {
              ElMessage.success(result.msg)
              vm.dialogFormVisible = false
              vm.form.email = ''
              vm.$refs[formName].resetFields()
            } else {
              vm.$refs[formName].resetFields()
              vm.form.email = ''
              ElMessage.success(result.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  beforeumount () {
    const vm = this
    if (vm.timers) {
      clearInterval(vm.timers)
      vm.timers = ''
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.company-title-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  text-align: left;
  .title-content {
    padding-bottom: 20px;
  }
  .tag-grey-btn {
    border-radius: 2px;
    padding: 5px 10px;
    color: #999999;
    font-size: 14px;
    border: 1px solid #999999;
    margin-left: 10px;
    cursor: pointer;
  }
  .activeFont {
    color: #fff;
    background-color: #5D6FE9;
    border: 1px solid #999999;
  }
  .bkd-color {
    padding: 20px;
    background: #F5F6FF;
  }
  .type-num-content {
    background-color: #F5F6FF;
    padding: 10px 22px;
  }
}
.report-content {
  border: 1px solid #ebebeb;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: space-between;
  .report-img-content {
    height: 130px;
    margin-right: 10px;
    width: 100px;
  }
  .report-right-content {
    display: flex;
    justify-content: flex-start;
    align-content: space-between;
    flex-wrap: wrap;
    height: 110px;
  }
  .btn-report {
    padding: 5px 10px;
    color: #fff;
    background-color: #5D6FE9;
    border-radius:4px;
    cursor: pointer;
  }
}
.btn-hover:hover{
 color: rgba(93, 111, 233, 1);
 border: 1px solid  rgba(93, 111, 233, 1);
}
.info-btn {
  color: #5D6FE9;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.info-btn:hover {
  font-weight: bold;
  color: rgba(93, 111, 233, 1);
}
.el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 0;
  margin-bottom: 10px;
}
</style>
