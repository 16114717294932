<template>
  <!--主要指标-->
  <div>
    <block>
      <div id="mainIndicatorCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div style="display: inline;">主要指标</div>
        <el-select v-model="selectValue" siz="small" filterable placeholder="请选择">
          <el-option
            v-for="item in selectValueArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <table style="width: 100%;" class="table-lsit">
        <tr>
          <th class="thWidth"></th>
          <th class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.reportDateZhongwen || '-' }}</th>
        </tr>
        <tr>
          <th class="thWidth">每股指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">基本每股收益(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.perBaseIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">扣非每股收益(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.perBuckleIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">稀释每股收益(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.perDilutedIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">每股净资产(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.perNetAssets) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">每股公积金(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.perAccumulationFund) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">每股未分配利润(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.undistributedEarnings) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">每股经营现金流(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operatingCashFlow) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">成长能力指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业总收入(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.totalOperatingIncomeGrew) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">毛利润(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.grossProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属净利润(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.netProfitAttributable) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">扣非净利润(元)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.deductionNetProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业总收入同比增长(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.totalOperatingIncomeGrew || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属净利润同比增长(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.attributableNetProfitRose || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">扣非净利润同比增长(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.netProfitRose || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业总收入滚动环比增长</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.totalOperatingIncomeGrowth || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属净利润滚动环比增长(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.netProfitAttributableRollingGrowth || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">扣非净利润滚动环比增长(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.deductionNonNetProfitGrowth || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">盈利能力指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">加权净资产收益率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.weightedReturnNetAssets || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">摊薄净资产收益率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.dilutedReturnNetAssets || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">摊薄总资产收益率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.dilutedTotalAssetsYield || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">毛利率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.grossProfitMargin || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">净利率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.netInterestRate || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">实际税率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.effectiveTaxRate || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">盈利质量指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">预收款/营业收入</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.advanceReceipts) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">销售现金流/营业收入</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.salesCashFlow) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">经营现金流/营业收入</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operatingCashFlowIncome) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">运营能力指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">总资产周转率(次)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.totalAssetTurnover || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">应收账款周转天数(天)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.accountsReceivableTurnoverDays || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">存货周转天数(天)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.inventoryTurnoverDays || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">财务风险指标</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">资产负债率(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.assetLiabilityRatio || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">流动负债/总负债(%)</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.currentLiabilities || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">流动比率</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.currentRatio || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">速动比率</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.quickRatio || '-' }}</td>
        </tr>
      </table>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { conversionNumIndex } from '@/utils/index' // 工具类
export default {
  name: 'main-indicator-count',
  props: {
    companyId: String
  },
  data () {
    return {
      dataList: [],
      selectValueArr: [],
      selectValue: '',
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getMainIndicatorYear',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.selectValueArr.push({
            value: '',
            label: '最近数据'
          })
          for (let i = 0; i < res.length; i++) {
            vm.selectValueArr.push({
              value: res[i],
              label: res[i] + '年度'
            })
          }
          vm.getDataList()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 去查询数据
    getDataList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/IpoCompany/getMainIndicatorList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          reportDate: vm.selectValue ? vm.selectValue : null
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.dataList = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    conversionNum (obj) {
      if (obj && obj !== 0 && obj !== '' && obj !== undefined && obj !== 'undefined') {
        return conversionNumIndex(obj)
      } else {
        return obj
      }
    }
  },
  watch: {
    selectValue () {
      const vm = this
      vm.getDataList()
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
   padding: 6px;
   border:solid #EBEEF5 1px;
 }
.table-lsit td{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.thWidth {
  width: 20%;
  height: 30px;
}
.textIndent {
  text-indent: 1em;
}
</style>
