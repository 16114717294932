<template>
  <!-- 开庭公告 -->
  <div>
    <block>
      <div id="courtnoticeCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>开庭公告 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="caseNo"
          label="案号"
          width="130">
        </el-table-column>
        <el-table-column
          prop="lianDate"
          label="开庭日期"
          width="120">
        </el-table-column>
        <el-table-column
          prop="caseReason"
          label="案由"
          width="170">
        </el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span v-if="scope.column.property == 'ownerComName'">
              上诉人/原告 - {{scope.row['prosecutor'] || '-'}}<br>
              被上诉人/被告 - {{scope.row['defendant'] || '-'}}
            </span>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="开庭公告详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="2">
            <template #label>案由</template>
            {{companyDetail.caseReason || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>案号</template>
            {{companyDetail.caseNo || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>开庭日期</template>
            {{companyDetail.lianDate || '-'}}
          </el-descriptions-item>
          <!-- <el-descriptions-item :span="1">
            <template #label>地区</template>
            {{companyDetail.province || '-'}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item :span="1">
            <template #label>排期日期</template>
            {{companyDetail.scheduleTime || '-'}}
          </el-descriptions-item> -->
          <el-descriptions-item :span="1">
            <template #label>承办部门</template>
            <div >{{companyDetail.undertakeDepartment || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>审判长/主审人</template>
            <div >{{companyDetail.chiefJudge || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>原告</template>
            <div >{{companyDetail.prosecutor || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被告</template>
            <div style="width: 100px;">{{companyDetail.defendant || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>法院</template>
            <div >{{companyDetail.executeGov || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>法庭</template>
            <div >{{companyDetail.executeUnite || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'courtnotice-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '案件角色',
        property: 'ownerComName'
      }, {
        label: '审理法院',
        property: 'executeGov'
      }],
      TableData: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      this.dialogTableVisible = true
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getCourtNoticeDetail',
        dateBackgroundRequestType: 'get',
        data: {
          courtNoticeId: e.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.lianDate = res.lianDate ? res.lianDate.substring(0, 10) : '-'
          res.publishDate = res.publishDate ? res.publishDate.substring(0, 10) : '-'
          res.billEndDate = res.billEndDate ? res.billEndDate.substring(0, 10) : '-'
          res.billBeginDate = res.billBeginDate ? res.billBeginDate.substring(0, 10) : '-'
          vm.companyDetail = res
          console.log(res)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getCourtNoticeList',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.lianDate = item.lianDate ? item.lianDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
