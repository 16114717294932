<template>
  <!--资产负债表-->
  <div>
    <block>
      <div id="balanceSheetCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div style="display: inline;">资产负债表</div>
        <el-select v-model="selectValue" siz="small" filterable placeholder="请选择">
          <el-option
            v-for="item in selectValueArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <table style="width: 100%;" class="table-lsit">
        <tr>
          <th class="thWidth"></th>
          <th class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.reportDateZhongwen || '-' }}</th>
        </tr>
        <tr>
          <th class="thWidth">流动资产</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">货币资金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["MONETARYFUND"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">以公允价值计量且其变动计入当期损益的金融资产</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">其中:交易性金融资产</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">应收票据</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["BILLREC"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">应收账款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["ACCOUNTREC"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">预付款项</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["ADVANCEPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其他应收款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERREC"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">存货</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["INVENTORY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其他流动资产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERLASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">流动资产合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMLASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">非流动资产</th>
          <td colspan="4"></td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">可供出售金融资产字段</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">长期股权投资</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["LTEQUITYINV"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">投资性房地产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["ESTATEINVEST"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">固定资产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["FIXEDASSET"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">在建工程</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">无形资产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["INTANGIBLEASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">商誉</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["GOODWILL"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">长期待摊费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["LTDEFERASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">递延所得税资产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DEFERINCOMETAXASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其他非流动资产</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERNONLASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">非流动资产合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMNONLASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">资产总计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMASSET"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">流动负债</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">短期借款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["STBORROW"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">应付票据</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">应付账款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["ACCOUNTPAY"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">预收款项</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">应付职工薪酬</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SALARYPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">应交税费</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["TAXPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">应付利息</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["INTERESTPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">应付股利</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DIVIDENDPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其他应付款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERPAY"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">一年内到期的非流动负债</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">其他流动负债</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERLLIAB"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">流动负债合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMLLIAB"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">非流动负债</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">长期借款</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["LTBORROW"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">应付债券</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">预计负债</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["ANTICIPATELIAB"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">递延所得税负债</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DEFERINCOMETAXLIAB"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">其他非流动负债</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <th class="thWidth">非流动负债合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMNONLLIAB"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">负债合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMLIAB"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">所有者权益(或股东权益)</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">实收资本（或股本）</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SHARECAPITAL"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">资本公积</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["CAPITALRESERVE"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">盈余公积</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SURPLUSRESERVE"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">未分配利润</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["RETAINEDEARNING"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">归属于母公司股东权益合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMPARENTEQUITY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">少数股东权益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["MINORITYEQUITY"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">股东权益合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMSHEQUITY"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">负债和股东权益合计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMLIABSHEQUITY"]) || '-' }}</td>
        </tr>
      </table>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { conversionNumIndex } from '@/utils/index' // 工具类
export default {
  name: 'balance-sheet-count',
  props: {
    companyId: String
  },
  data () {
    return {
      dataList: [],
      selectValueArr: [],
      selectValue: '',
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getBalanceSheetYear',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.selectValueArr.push({
            value: '',
            label: '最近数据'
          })
          for (let i = 0; i < res.length; i++) {
            vm.selectValueArr.push({
              value: res[i],
              label: res[i] + '年度'
            })
          }
          vm.getDataList()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 去查询数据
    getDataList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/IpoCompany/getBalanceSheetList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          reportDate: vm.selectValue ? vm.selectValue : null
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          for (let i = 0; i < res.length; i++) {
            if (res[i]?.reportJson) {
              res[i].reportJson = JSON.parse(res[i].reportJson)
            }
          }
          vm.dataList = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    conversionNum (obj) {
      if (obj && obj !== 0 && obj !== '' && obj !== undefined && obj !== 'undefined') {
        return conversionNumIndex(obj)
      } else {
        return obj
      }
    }
  },
  watch: {
    selectValue () {
      const vm = this
      vm.getDataList()
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.table-lsit td{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.thWidth {
  width: 20%;
  height: 30px;
}
.textIndent {
  text-indent: 1em;
}
</style>
