<template>
  <div class="img-content">
    <el-image style="width: 100%; height:30vw" :src="fuzzyData" fit="fill"></el-image>
    <div class="index-search-content dis-cen-cen">
      <div style="width: 100%;font-size: 30px;color: #333;">开通VIP即可查询全部信息</div>
      <div class="dis-aro-cen">
        <span class="btn-style" @click="openVip">立即开通</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'gaussian-blur',
  props: {
    pageName: String
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  mounted () {
    console.log(this.pageName)
    if (this.pageName === 'company-credential') {
      this.fuzzyData = this.list[0]
    } else {
      this.fuzzyData = this.list[0]
    }
  },
  data () {
    return {
      list: [
        require('@/assets/images/fuzzy_data.png')
      ],
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken'
    ])
  },
  methods: {
    openVip () {
      const vm = this
      if (vm.idToken) {
        const routeData = vm.$router.resolve({
          name: 'vip-index'
        })
        window.open(routeData.href, '_blank')
      } else {
        vm.loginEmit()
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.img-content {
  position: relative;
  text-align: center;
  .index-search-content {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -325px;
    border: 1px solid #E6E6E6;
    width: 650px;
    background-color: #fff;
    height: 300px;
    margin-top: -150px;
    flex-wrap: wrap;
  }
  .btn-style {
    display: block;
    width: 400px;
    height: 45px;
    line-height: 45px;
    background-color: #5D6FE9;
    color: #fff;
    font-size: 16px;
    margin-top: -20px;
    cursor: pointer;
  }
}
</style>
