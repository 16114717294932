<template>
  <!--利润表-->
  <div>
    <block>
      <div id="incomeStatementCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div style="display: inline;">利润表</div>
        <el-select v-model="selectValue" siz="small" filterable placeholder="请选择">
          <el-option
            v-for="item in selectValueArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <table style="width: 100%;" class="table-lsit">
        <tr>
          <th class="thWidth"></th>
          <th class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.reportDateZhongwen || '-' }}</th>
        </tr>
        <tr>
          <th class="thWidth">营业总收入</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.totalOperateReve) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业收入</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operateReve) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">营业总成本</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.totalOperateExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业成本</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operateExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">研发费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.rdExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">营业税金及附加</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operateTax) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">销售费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.saleExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">管理费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.manageExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">财务费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.financeExp) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">资产减值损失</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.assetdeValueLoss) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">其他经营收益</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">加:投资收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.investIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其中:对联营企业和合营企业的投资收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.investJointIncome) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">营业利润</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.operateProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">加:营业外收入</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.nonoperateReve) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">减:营业外支出</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.nonoperateExp) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">利润总额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.sumProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">减:所得税费用</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.incomeTax) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">净利润</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.netProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">其中:归属于母公司股东的净利润</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.parentnetProfit) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">少数股东损益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.minorityIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">扣除非经常性损益后的净利润</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.kcfjcxsyjlr) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">每股收益</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">基本每股收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.basicEps) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">稀释每股收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.dilutedEps) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">其他综合收益</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.othercIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属于母公司股东的其他综合收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.parentOtherCIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属于少数股东的其他综合收益</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.minorityOtherCIncome) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">综合收益总额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.sumCIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属于母公司所有者的综合收益总额</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.parentCIncome) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">归属于少数股东的综合收益总额</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.minorityCIncome) || '-' }}</td>
        </tr>
      </table>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { conversionNumIndex } from '@/utils/index' // 工具类
export default {
  name: 'income-statement-count',
  props: {
    companyId: String
  },
  data () {
    return {
      dataList: [],
      selectValueArr: [],
      selectValue: '',
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getIncomeStatementYear',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.selectValueArr.push({
            value: '',
            label: '最近数据'
          })
          for (let i = 0; i < res.length; i++) {
            vm.selectValueArr.push({
              value: res[i],
              label: res[i] + '年度'
            })
          }
          vm.getDataList()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 去查询数据
    getDataList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/IpoCompany/getIncomeStatementList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          reportDate: vm.selectValue ? vm.selectValue : null
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.dataList = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    conversionNum (obj) {
      if (obj && obj !== 0 && obj !== '' && obj !== undefined && obj !== 'undefined') {
        return conversionNumIndex(obj)
      } else {
        return obj
      }
    }
  },
  watch: {
    selectValue () {
      const vm = this
      vm.getDataList()
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.table-lsit td{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.thWidth {
  width: 20%;
  height: 30px;
}
.textIndent {
  text-indent: 1em;
}
</style>
