<template>
  <!-- 地块公示 -->
  <div>
    <block>
      <div id="landPublishCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>地块公示 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="address"
          label="土地位置"
          width="300">
        </el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
            <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="assigneeUnitName"
          label="受让单位"
          width="250">
        </el-table-column>
        <el-table-column
          prop="publishDate"
          label="发布日期"
          width="120">
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="地块公示详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
        <div style="padding-bottom: 10px;">地块基本信息</div>
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>宗地编号</template>
            {{companyDetail.landNo || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>土地位置</template>
            {{companyDetail.address || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>土地面积（公顷）</template>
            {{companyDetail.acreage || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>土地用途</template>
            {{companyDetail.purpose || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>受让单位</template>
            {{companyDetail.assigneeUnitName || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>备注</template>
            <div style="width: 150px;">{{companyDetail.remarks || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>公示期</template>
            <div style="width: 150px;">{{companyDetail.publishTerm || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发布机关</template>
            <div style="width: 150px;">{{companyDetail.publishGov || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发布日期</template>
            <div style="width: 150px;">{{companyDetail.publishDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label> 意见反馈方式</template>
            <div style="width: 150px;">{{companyDetail.explains || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="height: 40px;line-height: 40px;">联系方式</div>
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>联系单位</template>
            <div style="width: 150px;">
              {{companyDetail.cancellationFlag || '-'}}
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>单位地址</template>
            <div style="width: 150px;">{{companyDetail.contactUnit || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>邮政编码</template>
            <div style="width: 150px;">{{companyDetail.postCode || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>联系电话</template>
            <div style="width: 150px;">{{companyDetail.contactNumber || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>联系人</template>
            <div style="width: 150px;">{{companyDetail.contactPerson || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>电子邮件</template>
            <div style="width: 150px;">{{companyDetail.email || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'land-publish-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '土地面积（公顷）',
        property: 'acreage'
      }, {
        label: '土地用途',
        property: 'purpose'
      }],
      TableData: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      this.dialogTableVisible = true
      const vm = this
      const data = {
        dateBackgroundUrl: '/LandPublish/getLandPublishDetail',
        dateBackgroundRequestType: 'get',
        data: {
          lpId: e.lpId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          if (result.data) {
            console.log(result.data)
            res.publishDate = res.publishDate ? res.publishDate.substring(0, 10) : '-'
            vm.companyDetail = res
          } else {
            vm.companyDetail = {}
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/LandPublish/getLandPublishDetailList',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.publishDate = item.publishDate ? item.publishDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
