<template>
  <!--发行相关-->
  <block>
    <div id="infoIssueCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
    <div class="table-list-title dis-bet-cen">
      <div>发行相关</div>
    </div>
    <el-descriptions class="margin-top" :column="3" size="medium" border>
      <el-descriptions-item>
        <template #label>
          成立日期
        </template>
        {{ data.establishDate || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          上市日期
        </template>
        {{ data.marketDate || "-" }}{{ data.registcapiunit }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          网上发行日期
        </template>
        {{ data.internetReleasesDate || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          发行方式
        </template>
        {{ data.releasesType || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          发行市盈率(倍)
        </template>
        {{ data.releasesRate || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          发行量(股)
        </template>
        {{ data.releasesAmount || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          发行费用(元)
        </template>
        {{ data.releasesCost || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          募集资金净额(元)
        </template>
        {{ data.netraisedFunds || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          发行总市值(元)
        </template>
        {{ data.releasesTotal || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          首日开盘价(元)
        </template>
        {{ data.firstdayOpenPrice || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          首日收盘价(元)
        </template>
        {{ data.firstdayClosePrice || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          首日换手率
        </template>
        {{ data.firstdayChangeRate || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          每股面值(元)
        </template>
        {{ data.parValue || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          每股发行价(元)
        </template>
        {{ data.parReleasesValue || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          定价中签率
        </template>
        {{ data.pricingsuccRate || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          网下配售中签率
        </template>
        {{ data.downRate || '-' }}
      </el-descriptions-item>
    </el-descriptions>
  </block>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'info-issue-count',
  props: { companyId: String },
  data () {
    return {
      data: {},
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getInfoIssue',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.data = res
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
