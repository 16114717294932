<template>
  <!-- 简易注销 -->
  <div>
    <block>
      <div id="simplecancellationCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>简易注销 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
    </block>
    <table class="table-lsit" border="0" cellpadding='1'>
      <tr>
        <th rowspan="5" style="background-color:rgb(232 235 251);border:solid #d6d4d4 1px;">企业公告信息</th>
        <th>企业名称</th>
        <th>{{TableData.companyName || '-'}}</th>
      </tr>
      <tr>
        <th>统一社会信用代码/注册号</th>
        <th>{{TableData.regNoOrCreditCode || '-'}}</th>
      </tr>
      <tr>
        <th>登记机关</th>
        <th>{{TableData.registration || '-'}}</th>
      </tr>
      <tr>
        <th>公告期</th>
        <th>{{TableData.esPublicDate || '-'}}</th>
      </tr>
      <tr>
        <th>全体投资人承诺书</th>
        <th>
          <span v-if="TableData.docUrl" @click="handleTo(TableData.docUrl)" style="color: #5D6FE9;cursor: pointer;">查看详情</span>
          <span v-else>-</span>
        </th>
      </tr>
      <tr>
        <th rowspan="3" style="background-color:rgb(232 235 251);border:solid #d6d4d4 1px;"> 异议信息</th>
        <th>异议申请人</th>
        <th>{{TableData.dissentPerson || '-'}}</th>
      </tr>
      <tr>
        <th>异议时间</th>
        <th>{{TableData.dissentDate || '-'}}</th>
      </tr>
      <tr>
        <th>异议内容</th>
        <th>{{TableData.dissentContent || '-'}}</th>
      </tr>
      <tr>
        <th rowspan="2" style="background-color:rgb(232 235 251);border:solid #d6d4d4 1px;">简易注销结果</th>
        <th>简易注销结果</th>
        <th>{{TableData.resultContent || '-'}}</th>
      </tr>
      <tr>
        <th> 公告申请日期</th>
        <th>{{TableData.escrPublicDate || '-'}}</th>
      </tr>
    </table>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'land-publish-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '土地位置',
        property: 'address'
      }, {
        label: '土地面积（公顷）',
        property: 'acreage'
      }, {
        label: '成交价格（万元）',
        property: 'tradeType'
      }, {
        label: '土地用途',
        property: 'regDate'
      }, {
        label: '受让单位',
        property: 'assigneeUnitName'
      }, {
        label: '发布日期',
        property: 'publishDate'
      }],
      TableData: {}
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    handleTo: function (e) {
      window.open(e)
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/EciSimplecancellation/getSimplecancellation',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(result)
          if (res) {
            vm.TableData = res
          }
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
.table-border-bottom {
  width: 100%;
  min-width: 700px;
  border-bottom: 1px solid #EBEEF5;
  height: 40px;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
  padding: 12px;
  border:solid #EBEEF5 1px;
}
</style>
