<template>
  <!-- 立案信息 -->
  <div>
    <block>
      <div id="registerCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>立案信息 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span v-if="scope.column.property == 'prosecutorname'">
              上诉人/原告 - <span v-for="item of scope.row['prosecutor']" :key="item">{{item.Name + ','}}</span><br>
              被上诉人/被告 - <span v-for="item of scope.row['appellee']" :key="item">{{item.Name + ','}}</span>
            </span>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="立案信息详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="2">
            <template #label>案由</template>
            {{companyDetail.reason || "-"}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>案号</template>
            {{companyDetail.caseNo || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>立案日期</template>
            {{companyDetail.registDate || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>开庭时间</template>
            {{companyDetail.holdDate || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>结束时间</template>
            {{companyDetail.finishDate || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>承办部门</template>
            <div>{{companyDetail.department || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>法院</template>
            <div>{{companyDetail.court || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>承办法官</template>
            <div>{{companyDetail.judger || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>法官助理</template>
            <div>{{companyDetail.assistant || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>案件类型</template>
            <div>{{companyDetail.caseType || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>案件状态</template>
            <div>{{companyDetail.caseStatus || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>当事人</template>
            <div>
              上诉人/原告 - <span v-for="item of companyDetail.prosecutor" :key="item">{{item.Name + ','}}</span><br>
              被上诉人/被告 - <span v-for="item of companyDetail.appellee" :key="item">{{item.Name + ','}}</span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'register-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '案号',
        property: 'caseNo'
      }, {
        label: '当事人',
        property: 'prosecutorname'
      }, {
        label: '立案时间',
        property: 'punishDate'
      }],
      TableData: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      this.dialogTableVisible = true
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getRegisterDetail',
        dateBackgroundRequestType: 'get',
        data: {
          registerId: e.registerId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.registDate = res.registDate ? res.registDate.substring(0, 10) : '-'
          res.holdDate = res.holdDate ? res.holdDate.substring(0, 10) : '-'
          res.prosecutor = res.prosecutor ? JSON.parse(res.prosecutor) : []
          res.appellee = res.prosecutor ? JSON.parse(res.appellee) : []
          vm.companyDetail = res
          console.log(res)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getRegisterList',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          console.log(res)
          res.list.forEach(item => {
            item.punishDate = item.punishDate ? item.punishDate.substring(0, 10) : '-'
            item.prosecutor = item.prosecutor ? JSON.parse(item.prosecutor) : []
            item.appellee = item.appellee ? JSON.parse(item.appellee) : []
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
