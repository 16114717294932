<template>
  <!--十大股东-->
  <block>
    <div id="toptenholderCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
    <div class="table-list-title dis-bet-cen">
      <div>十大股东</div>
    </div>
    <div class="table-box">
      <el-table
        :data="dataList"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        border
        style="width: 100%">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item, index) in tableConfig" :key="index"
                         :prop="item.value"
                         :label="item.label"
                         :width="item.width"></el-table-column>
      </el-table>
    </div>
  </block>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { parseTime } from '@/utils/index' // 工具类
export default {
  name: 'toptenholder-count',
  props: { companyId: String },
  data () {
    return {
      tableConfig: [
        {
          label: '股东名称',
          value: 'holderName'
        },
        {
          label: '股份类型',
          value: 'type',
          width: '120'
        },
        {
          label: '持股数',
          value: 'count',
          width: '150'
        },
        {
          label: '持股比例',
          value: 'proportion',
          width: '120'
        },
        // {
        // label: '增减',
        // value: 'changeCount',
        // width: '150'
        // },
        {
          label: '变动比例',
          value: 'changeProportion',
          width: '120'
        },
        {
          label: '公示时间',
          value: 'publicDateStr',
          width: '120'
        }
      ],
      dataList: [],
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getToptenholderList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          pageNum: 1,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.dataList = res?.list ? res.list : []
          // console.log('查询成功: ' + JSON.stringify(vm.dataList))
          for (let i = 0; i < vm.dataList.length; i++) {
            vm.dataList[i].publicDateStr = vm.dataList[i].publicDate ? parseTime(vm.dataList[i].publicDate, '{y}-{m}-{d}') : ''
          }
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
.table-box {
  width: 100%;
  /deep/.el-table th > .cell {
    text-align: center;
  }

  /deep/.el-table .cell {
    text-align: center;
  }
}
</style>
