<template>
  <!--基本信息-->
  <block>
    <div id="infoCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
    <div class="table-list-title dis-bet-cen">
      <div>基本信息</div>
    </div>
    <el-descriptions class="margin-top" :column="2" size="medium" border>
      <el-descriptions-item>
        <template #label>
          企业全称
        </template>
        {{ data.companyName || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          英文名称
        </template>
        {{ data.englishName || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          上市曾用名
        </template>
        {{ data.historyName || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          工商登记
        </template>
        {{ data.regNo || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          所属行业
        </template>
        {{ data.industry || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          注册资本(元)
        </template>
        {{ data.regCapital || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          雇员人数
        </template>
        {{ data.personNumber || '-'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          管理人员人数
        </template>
        {{ data.managerNumber || '-'}}
      </el-descriptions-item>
    </el-descriptions>
  </block>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'info-count',
  props: { companyId: String },
  data () {
    return {
      data: {}
    }
  },
  mounted () {
    const vm = this
    vm.initData()
  },
  methods: {
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/IpoCompany/getInfo',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data ? result.data : {}
          // console.log('查询成功: ' + JSON.stringify(res))
          vm.data = res
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
  .table-list-title {
    padding: 18px 0;
    text-align: left;
    color: #333;
    font-weight: bold;
  }
  .table-page {
    text-align: right;
    margin-top: 10px;
  }
</style>
