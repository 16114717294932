<template>
  <!-- 司法协助 -->
  <div>
    <block>
      <div id="assistanceCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>司法协助 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button type="text" v-if="scope.row.status === '解除冻结' || scope.row.status === '冻结' || scope.row.status === '股权冻结|冻结' || scope.row.status === '股权冻结|解除冻结' || scope.row.status == '股权冻结 | 解除冻结' || scope.row.status == '股权冻结 | 冻结'" @click="handleTo(scope.row)">详情</el-button>
             <span v-else>-</span>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="司法协助详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
        <div style="padding-bottom: 10px;" v-if="status === '1'">冻结情况</div>
        <el-descriptions class="margin-top" v-if="status === '1'" :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>执行文书文号</template>
            {{companyDetail.fExecutionDocNum || "-"}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行事项</template>
            {{companyDetail.fExecutionMatters || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行法院</template>
            {{companyDetail.enforcementCourt || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行裁定书文号</template>
            {{companyDetail.fExecutionVerdictNum || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人</template>
            {{companyDetail.executedBy || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>股权数额</template>
            {{companyDetail.equityAmount || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人证件种类</template>
            <div>{{companyDetail.fExecutedPersonDocType || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人证件号码</template>
            <div>{{companyDetail.fExecutedPersonDocNum || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>冻结开始日期</template>
            <div>{{companyDetail.fFreezeStartDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>冻结结束日期</template>
            <div>{{companyDetail.fFreezeEndDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>冻结期限</template>
            <div>{{companyDetail.fFreezeTerm || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>公示日期</template>
            <div>{{companyDetail.fPublicDate || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="padding-bottom: 10px;" v-if="status === '2'">解冻情况</div>
        <el-descriptions class="margin-top" v-if="status === '2'" :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label> 执行文书文号</template>
            {{companyDetail.executionNoticeNum || "-"}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行事项</template>
            {{companyDetail.uExecutionMatters || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行法院</template>
            {{companyDetail.enforcementCourt || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执行裁定书文号</template>
            {{companyDetail.uExecutionVerdictNum || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人</template>
            {{companyDetail.executedBy || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>股权数额</template>
            <div>{{companyDetail.equityAmount || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人证件种类</template>
            <div>{{companyDetail.uExecutedPersonDocType || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>被执行人证件号码</template>
            <div>{{companyDetail.uExecutedPersonDocNum || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>解除冻结日期</template>
            <div>{{companyDetail.uUnFreezeDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>公示日期</template>
            <div>{{companyDetail.uPublicDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>解冻机关</template>
            <div>{{companyDetail.uThawOrgan || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>解冻文书号</template>
            <div>{{companyDetail.uThawDocNo || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'assistance-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '被执行人',
        property: 'executedBy'
      }, {
        label: '股权数额',
        property: 'equityAmount'
      }, {
        label: '执行法院',
        property: 'enforcementCourt'
      }, {
        label: '执行裁定书文号',
        property: 'executionNoticeNum'
      }, {
        label: '类型 丨 状态',
        property: 'status'
      }],
      TableData: [],
      status: '1'
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      const vm = this
      if (e.status === '股权冻结|冻结' || e.status === '冻结' || e.status === '股权冻结 | 冻结') {
        vm.status = '1'
      } else {
        vm.status = '2'
      }
      vm.companyDetail = {}
      this.dialogTableVisible = true
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getAssistanceDetail',
        dateBackgroundRequestType: 'get',
        data: {
          id: e.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          // res.registDate = res.registDate ? res.registDate.substring(0, 10) : '-'
          // res.holdDate = res.holdDate ? res.holdDate.substring(0, 10) : '-'
          vm.companyDetail = res
          console.log(res)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyAdministrativePenalty/getAssistanceList',
        dateBackgroundRequestType: 'get',
        data: {
          qccCompanyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          console.log(res)
          res.list.forEach(item => {
            item.lianDate = item.lianDate ? item.lianDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
