<template>
  <!--对外担保-->
  <div>
    <block>
      <div id="importantDwdbCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>对外担保 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="tableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
        </el-table-column>
        <el-table-column
          label="操作"
          width="80">
          <template #default="scope">
            <el-button type="text" @click="companyDetailsClick(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
    </block>
    <el-dialog
      append-to-body
      :close-on-click-modal="true"
      title="对外担保详情"
      v-model="dialogShow"
      width="50%"
      :before-close="handleClose">
      <el-descriptions class="margin-top" :column="2" size="medium" border>
        <el-descriptions-item>
          <template #label>
            担保方
          </template>
          {{ dialogItem.securedParty }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            被担保方
          </template>
          {{ dialogItem.bsecuredParty }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            公告日期
          </template>
          {{ dialogItem.publicDateStr }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            担保方式
          </template>
          {{ dialogItem.securedType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            担保金额（万元）
          </template>
          {{ dialogItem.securedAmount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            币种
          </template>
          {{ dialogItem.currency }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            担保期限
          </template>
          {{ dialogItem.guaranteePeriod }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            担保起始日
          </template>
          {{ dialogItem.guaranteeStartDateStr }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            担保终止日
          </template>
          {{ dialogItem.guaranteeEndDateStr }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            是否履行完毕
          </template>
          {{ dialogItem.isFinished }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            是否关联交易
          </template>
          {{ dialogItem.isTransactions }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            交易日期
          </template>
          {{ dialogItem.transactionDateStr }}
        </el-descriptions-item>
        <!-- <el-descriptions-item>
          <template #label>
            担保事件说明
          </template>
          {{ dialogItem.guaranteeContent }}
        </el-descriptions-item> -->
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { parseTime } from '@/utils/index' // 工具类
export default {
  name: 'important-dwdb-count',
  props: { companyId: String },
  data () {
    return {
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '被担保方',
        property: 'bsecuredParty'
      }, {
        label: '担保方',
        property: 'securedParty'
      }, {
        label: '担保方式',
        property: 'securedType'
      }, {
        label: '担保金额',
        property: 'securedAmount'
      }, {
        label: '立案时间',
        property: 'publicDateStr'
      }],
      tableData: [],
      dialogShow: false,
      dialogItem: {},
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      const vm = this
      vm.page.pageNum = e
      vm.initData(vm.companyCode)
    },
    // 点击了 公司名 跳转到公司详情
    companyDetailsClick (item) {
      const vm = this
      vm.dialogItem = item
      vm.dialogShow = true
    },
    initData: function (companyCode) {
      const vm = this
      // 查询公司代码 000651
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getImportantDwdbList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.publicDateStr = item.publicDate ? parseTime(item.publicDate, '{y}-{m}-{d}') : '-'
            item.guaranteeStartDateStr = item.guaranteeStartDate ? parseTime(item.guaranteeStartDate, '{y}-{m}-{d}') : '-'
            item.guaranteeEndDateStr = item.guaranteeEndDate ? parseTime(item.guaranteeEndDate, '{y}-{m}-{d}') : '-'
            item.transactionDateStr = item.transactionDate ? parseTime(item.transactionDate, '{y}-{m}-{d}') : '-'
            arr.push(item)
          })
          vm.tableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
