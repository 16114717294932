<template>
  <!-- 动产抵押 -->
  <div>
    <block>
      <div id="movablespledgeCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>动产抵押 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
            <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="动产抵押详情" :append-to-body="appendtobody" v-model="dialogTableVisible">
        <div style="padding-bottom: 10px;">动产抵押登记信息</div>
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="2">
            <template #label>登记机关</template>
            {{companyDetail.registeroffice || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>登记编号</template>
            {{companyDetail.registerno || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>登记日期</template>
            {{companyDetail.registerdate || '-'}}
          </el-descriptions-item>
        </el-descriptions>
        <div style="padding-bottom: 10px;">抵押权人信息</div>
        <el-descriptions class="margin-top" v-if="chattelMortgagePledgee.length > 0" :column="2" :size="size" border>
          <span v-for="(item, index) of chattelMortgagePledgee" :key="index">
          <el-descriptions-item :span="2">
            <template #label>抵押权人名称</template>
            {{item.name || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>证照号码</template>
            {{item.identityNo || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>抵押权人证照类型</template>
            {{item.identityType || '-'}}
          </el-descriptions-item>
          </span>
        </el-descriptions>
        <span v-else>--</span>
        <div style="padding-bottom: 10px;">被担保主债权信息</div>
        <el-descriptions class="margin-top" v-if="mortgageGuaranteedCredRight.length > 0"  :column="2" :size="size" border>
          <span v-for="(item, index) of mortgageGuaranteedCredRight" :key="index">
          <el-descriptions-item :span="1">
            <template #label>种类</template>
            {{item.kind || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>数额</template>
            {{item.amount || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>担保的范围</template>
            <div style="width: 200px">{{item.assuranceScope || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>备注</template>
            {{item.remark || '-'}}
          </el-descriptions-item>
          </span>
        </el-descriptions>
        <span v-else>--</span>
        <div style="padding-bottom: 10px;">抵押物信息</div>
        <el-descriptions class="margin-top" v-if="mortgageGuarantee.length > 0"  :column="2" :size="size" border>
          <span v-for="(item, index) of mortgageGuarantee" :key="index" >
          <el-descriptions-item :span="1">
            <template #label>抵押物名称</template>
            {{item.name}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>所有权或使用权归属</template>
            {{item.ownership}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>数量、质量、状况、所在地等情况</template>
            <div style="width: 150px">{{item.other}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>备注</template>
             {{item.remark ? item.remark : '-'}}
          </el-descriptions-item>
          </span>
        </el-descriptions>
        <span v-else>--</span>
        <div style="padding-bottom: 10px;">变更信息</div>
        <el-descriptions class="margin-top"  v-if="chattelMortgage.length > 0"  :column="2" :size="size" border>
          <span v-for="(item, index) of chattelMortgage" :key="index" >
          <el-descriptions-item :span="1">
            <template #label>变更日期</template>
            {{item.changeDate}}
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template #label>变更内容</template>
            <div style="width: 200px">{{item.changeContent}}</div>
          </el-descriptions-item>
          </span>
        </el-descriptions>
        <span v-else>--</span>
        <div style="padding-bottom: 10px;">注销信息</div>
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="2">
            <template #label>注销日期</template>
            {{companyDetail.cancelDate || '-'}}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>注销原因</template>
            {{companyDetail.cancelReason || '-'}}
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'movablespledge-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '登记编号',
        property: 'registerno'
      }, {
        label: '登记日期',
        property: 'registerdate'
      }, {
        label: '公示日期',
        property: 'publicDate'
      }, {
        label: '登记机关',
        property: 'registeroffice'
      }, {
        label: '被担保债券数额',
        property: 'debtsecuredamount'
      }, {
        label: '状态',
        property: 'status'
      }],
      TableData: [],
      chattelMortgage: [],
      mortgageGuarantee: [],
      mortgageGuaranteedCredRight: [],
      chattelMortgagePledgee: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      this.dialogTableVisible = true
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgageDetail', // /PublishNotice/getPublishNoticeDetail
        dateBackgroundRequestType: 'get',
        data: {
          movablespledgeId: e.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.registerdate = res.registerdate ? res.registerdate.substring(0, 10) : '-'
          res.publishDate = res.publishDate ? res.publishDate.substring(0, 10) : '-'
          res.billEndDate = res.billEndDate ? res.billEndDate.substring(0, 10) : '-'
          res.billBeginDate = res.billBeginDate ? res.billBeginDate.substring(0, 10) : '-'
          vm.companyDetail = res
          console.log(res)
        }
      }).catch(error => {
        console.log(error)
      })
      // 变更信息
      const data1 = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgageChange', // /PublishNotice/getPublishNoticeDetail
        dateBackgroundRequestType: 'get',
        data: {
          movablespledgeId: e.id
        }
      }
      forwardBG(data1).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.forEach(item => {
            item.changeDate = item.changeDate ? item.changeDate.substring(0, 10) : '-'
          })
          vm.chattelMortgage = res
        }
      }).catch(error => {
        console.log(error)
      })
      // 抵押物信息
      const data2 = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgageGuarantee', // /PublishNotice/getPublishNoticeDetail
        dateBackgroundRequestType: 'get',
        data: {
          movablespledgeId: e.id
        }
      }
      forwardBG(data2).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.forEach(item => {
            item.changeDate = item.changeDate ? item.changeDate.substring(0, 10) : '-'
          })
          vm.mortgageGuarantee = res
        }
      }).catch(error => {
        console.log(error)
      })
      // 动产抵押被担保主债权信息
      const data3 = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgageGuaranteedCredRight', // /PublishNotice/getPublishNoticeDetail
        dateBackgroundRequestType: 'get',
        data: {
          movablespledgeId: e.id
        }
      }
      forwardBG(data3).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.forEach(item => {
            item.changeDate = item.changeDate ? item.changeDate.substring(0, 10) : '-'
          })
          vm.mortgageGuaranteedCredRight = res
        }
      }).catch(error => {
        console.log(error)
      })
      // 动产抵押抵押权人信息

      const data4 = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgagePledgee', // /PublishNotice/getPublishNoticeDetail
        dateBackgroundRequestType: 'get',
        data: {
          movablespledgeId: e.id
        }
      }
      forwardBG(data4).then(result => {
        if (result.code === 0) {
          const res = result.data
          res.forEach(item => {
            item.changeDate = item.changeDate ? item.changeDate.substring(0, 10) : '-'
          })
          vm.chattelMortgagePledgee = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyOperatingState/chattelMortgageList',
        dateBackgroundRequestType: 'get',
        data: {
          qcc_company_id: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.publishDate = item.publishDate ? item.publishDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
