<template>
  <!-- 法律诉讼 -->
  <div>
    <block>
      <div id="judgementCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>法律诉讼 <span style="color: #5D6FE9;">{{page.total}}</span></div>
        <el-select v-model="honorValue" @change="handleHonorSelect" placeholder="请选择" >
          <el-option
            v-for="item in honorOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="casename"
          label="案件名称"
          width="200">
        </el-table-column>
        <el-table-column
          prop="caseno"
          label="案件编号"
          width="130">
        </el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span v-if="scope.column.property === 'caseroles'">
              <div :key="index"  v-for="(item, index) of scope.row['caseroles']">{{item.R}}-{{item.P}}</div><br/>
            </span>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="caseroles"
          label="案件角色"
          width="250">
          <template #default="scope">
            <span v-if="scope.column.property === 'caseroles'">
              <div :key="index"  v-for="(item, index) of scope.row['caseroles']">{{item.R}}-{{item.P}}</div><br/>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="casetype"
          label="案件类型"
          width="100">
        </el-table-column>
        <el-table-column
          prop="court"
          label="审理法院"
          width="120">
        </el-table-column>
        <el-table-column
          prop="submitdate"
          label="发布日期"
          width="110">
        </el-table-column>
        <el-table-column
            label="操作"
            width="80">
            <template #default="scope">
             <el-button v-if="scope.row['fmtContent']" type="text" @click="handleTo(scope.row)">详情</el-button>
             <span v-else>-</span>
            </template>
          </el-table-column>
      </el-table>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'judgement-count',
  props: { companyId: String },
  data () {
    return {
      appendtobody: true,
      companyDetail: {},
      dialogTableVisible: false,
      page: {
        total: 0,
        pageNum: 1
      },
      // 荣誉分类
      honorOption: [{
        value: '',
        label: '全部',
        num: 0
      }, {
        value: 'ms',
        label: '民事',
        num: 0
      }, {
        value: 'zx',
        label: '执行',
        num: 0
      }, {
        value: 'xs',
        label: '刑事',
        num: 0
      }, {
        value: 'xz',
        label: '行政',
        num: 0
      }, {
        value: 'other',
        label: '其他',
        num: 0
      }],
      honorValue: '',
      // 股东信息
      tableHeader: [{
        label: '案由',
        property: 'casereason'
      }],
      TableData: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    handleTo (e) {
      const vm = this
      sessionStorage.removeItem('law-mes-item')
      sessionStorage.setItem('law-mes-item', JSON.stringify(e))
      const routeData = vm.$router.resolve({
        name: 'law-mes'
      })
      window.open(routeData.href, '_blank')
    },
    handleHonorSelect: function () {
      this.page.pageNum = 1
      this.initData()
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyCredit/getJudgementDetailsList',
        dateBackgroundRequestType: 'get',
        data: {
          qcc_company_id: vm.companyId,
          caseType: vm.honorValue === '0' ? null : vm.honorValue,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.submitdate = item.submitdate ? item.submitdate.substring(0, 10) : '-'
            item.publishdate = item.publishdate ? item.publishdate.substring(0, 10) : '-'
            item.caseroles = item.caserole ? JSON.parse(item.caserole) : []
            if (item.casetype === 'xs') {
              item.casetype = '刑事案件'
            } else if (item.casetype === 'ms') {
              item.casetype = '民事案件'
            } else if (item.casetype === 'xz') {
              item.casetype = '行政案件'
            } else if (item.casetype === 'zx') {
              item.casetype = '执行案件'
            } else if (item.casetype === 'other') {
              item.casetype = '其他案件案件'
            } else if (item.casetype === 'gx') {
              item.casetype = '管辖案件'
            } else if (item.casetype === 'bq') {
              item.casetype = '非诉保全审查案件'
            } else if (item.casetype === 'qs') {
              item.casetype = '强制清算与破产案件'
            } else if (item.casetype === 'pc') {
              item.casetype = '赔偿案件'
            } else if (item.casetype === 'zscq') {
              item.casetype = '知识产权案件'
            } else if (item.casetype === 'qt') {
              item.casetype = '其他案件'
            } else if (item.casetype === 'zc') {
              item.casetype = '司法制裁案件'
            } else if (item.casetype === 'gsx') {
              item.casetype = '国际司法协助案件'
            } else if (item.casetype === 'msc') {
              item.casetype = '民事裁定书'
            } else if (item.casetype === 'qsx') {
              item.casetype = '区际司法协助案件'
            } else if (item.casetype === 'bh') {
              item.casetype = '驳回案件'
            }
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
</style>
