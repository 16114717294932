<template>
  <div class="img-content">
    <el-image style="width: 100%; height:30vw" :src="fuzzyData" fit="fill"></el-image>
    <div class="index-search-content dis-cen-cen">
      <div style="width: 100%;font-size: 30px;color: #333;">登录后，查看更多信息</div>
      <div class="dis-aro-cen">
        <span class="btn-style" @click="loginEmit">立即登录</span>
        <span class="btn-style" style="margin-left: 10px;background-color: #fff;color: #5D6FE9;border: 1px solid #5D6FE9;" @click="handleTo">免费注册</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gaussian-blur',
  props: {
    pageName: String
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  mounted () {
    if (this.pageName === 'company-credential') {
      this.fuzzyData = this.list[0]
    } else {
      this.fuzzyData = this.list[0]
    }
  },
  methods: {
    handleTo () {
      this.$router.push({
        name: 'Register'
      })
    }
  },
  data () {
    return {
      list: [
        require('@/assets/images/fuzzy_data.png')
      ],
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.img-content {
  position: relative;
  .index-search-content {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -325px;
    border: 1px solid #E6E6E6;
    width: 650px;
    background-color: #fff;
    height: 300px;
    margin-top: -150px;
    flex-wrap: wrap;
  }
  .btn-style {
    display: block;
    width: 200px;
    height: 45px;
    line-height: 45px;
    background-color: #5D6FE9;
    color: #fff;
    font-size: 16px;
    margin-top: -20px;
    cursor: pointer;
  }
}
</style>
