<template>
  <!--现金流量-->
  <div>
    <block>
      <div id="cashFlowStatementCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div style="display: inline;">现金流量表</div>
        <el-select v-model="selectValue" siz="small" filterable placeholder="请选择">
          <el-option
            v-for="item in selectValueArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <table style="width: 100%;" class="table-lsit">
        <tr>
          <th class="thWidth"></th>
          <th class="thWidth" align="center" v-for="index in 4" :key="index">{{ dataList[index - 1]?.reportDateZhongwen || '-' }}</th>
        </tr>
        <tr>
          <th class="thWidth">经营活动产生的现金流量</th>
          <td colspan="4"></td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">销售商品、提供劳务收到的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">收到其他与经营活动有关的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHEROPERATEREC"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">经营活动现金流入小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMOPERATEFLOWIN"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">购买商品、接受劳务支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["BUYGOODSSERVICEPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">支付给职工以及为职工支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["EMPLOYEEPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">支付的各项税费</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["TAXPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">支付其他与经营活动有关的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHEROPERATEPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">经营活动现金流出小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMOPERATEFLOWOUT"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">经营活动产生的现金流量净额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["NETOPERATECASHFLOW"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">投资活动产生的现金流量</th>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="thWidth textIndent">收回投资收到的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DISPOSALINVREC"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">取得投资收益收到的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["INVINCOMEREC"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">处置固定资产、无形资产和其他长期资产收回的现金净额</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DISPFILASSETREC"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">处置子公司及其他营业单位收到的现金净额</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">收到其他与投资活动有关的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <th class="thWidth">投资活动现金流入小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMINVFLOWIN"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">购建固定资产、无形资产和其他长期资产支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["BUYFILASSETPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">投资支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["INVPAY"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">取得子公司及其他营业单位支付的现金净额</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">支付其他与投资活动有关的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERINVPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">投资活动现金流出小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMINVFLOWOUT"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">投资活动产生的现金流量净额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["NETINVCASHFLOW"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">筹资活动产生的现金流量</th>
          <td colspan="4"></td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">吸收投资收到的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">子公司吸收少数股东投资收到的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">取得借款收到的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["LOANREC"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">发行债券收到的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">收到其他与筹资活动有关的现金</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <th class="thWidth">筹资活动现金流入小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMFINAFLOWIN"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">偿还债务支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["REPAYDEBTPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">分配股利、利润或偿付利息支付的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["DIVIPROFITORINTPAY"]) || '-' }}</td>
        </tr>
        <!-- 天眼查天眼查 -->
        <!--<tr>-->
          <!--<td class="thWidth textIndent">子公司支付给少数股东的股利、利润</td>-->
          <!--<td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["sssssssssssssss"]) || '-' }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="thWidth textIndent">支付其他与筹资活动有关的现金</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["OTHERFINAPAY"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">筹资活动现金流出小计</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["SUMFINAFLOWOUT"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">筹资活动产生的现金流量净额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["NETFINACASHFLOW"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">汇率变动对现金及现金等价物的影响</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["EFFECTEXCHANGERATE"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">现金及现金等价物净增加额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["NICASHEQUI"]) || '-' }}</td>
        </tr>
        <tr>
          <td class="thWidth textIndent">加:期初现金及现金等价物余额</td>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["CASHEQUIBEGINNING"]) || '-' }}</td>
        </tr>
        <tr>
          <th class="thWidth">期末现金及现金等价物余额</th>
          <td class="thWidth" align="center" v-for="index in 4" :key="index">{{ conversionNum(dataList[index - 1]?.reportJson["CASHEQUIENDING"]) || '-' }}</td>
        </tr>
      </table>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { conversionNumIndex } from '@/utils/index' // 工具类
export default {
  name: 'cash-flow-statement-count',
  props: {
    companyId: String
  },
  data () {
    return {
      dataList: [],
      selectValueArr: [],
      selectValue: '',
      companyCode: ''
    }
  },
  mounted () {
  },
  methods: {
    initData: function (companyCode) {
      const vm = this
      vm.companyCode = companyCode
      const data = {
        dateBackgroundUrl: '/IpoCompany/getCashFlowStatementYear',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.selectValueArr.push({
            value: '',
            label: '最近数据'
          })
          for (let i = 0; i < res.length; i++) {
            vm.selectValueArr.push({
              value: res[i],
              label: res[i] + '年度'
            })
          }
          vm.getDataList()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 去查询数据
    getDataList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/IpoCompany/getCashFlowStatementList',
        dateBackgroundRequestType: 'get',
        data: {
          companyCode: vm.companyCode,
          reportDate: vm.selectValue ? vm.selectValue : null
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          for (let i = 0; i < res.length; i++) {
            if (res[i]?.reportJson) {
              res[i].reportJson = JSON.parse(res[i].reportJson)
            }
          }
          console.log(res)
          vm.dataList = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    conversionNum (obj) {
      if (obj && obj !== 0 && obj !== '' && obj !== undefined && obj !== 'undefined') {
        return conversionNumIndex(obj)
      } else {
        return obj
      }
    }
  },
  watch: {
    selectValue () {
      const vm = this
      vm.getDataList()
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.table-lsit td{
  padding: 6px;
  border:solid #EBEEF5 1px;
}
.thWidth {
  width: 20%;
  height: 30px;
}
.textIndent {
  text-indent: 1em;
}
</style>
