<template>
  <!-- 人员业绩 -->
  <div>
    <block>
      <div id="companyPersonnelResultsCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>人员业绩 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :span-method="objectSpanMethod"
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        :highlight-current-row="isfalse"
        style="width: 100%;">
        <el-table-column
            label="序号"
            width="50">
            <template #default="scope">
              <span style="margin-left: 10px">{{ scope.row.index }}</span>
            </template>
          </el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span v-if="scope.column.property === 'projectName'" style="color: #5D6FE9;cursor: pointer;" @click="handleTo(scope.row)">{{scope.row[scope.column.property] || '-'}}</span>
            <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80">
          <template #default="scope">
           <el-popover placement="left" :width="400" trigger="click" v-if="scope.row['companyRegisteredPersonnelList']?.length > 0">
             <template #reference>
               <span style="font-size: 14px;color: #5D6FE9;font-weight: 400;">关联证书</span>
             </template>
             <el-table :data="scope.row['companyRegisteredPersonnelList']">
                <el-table-column  property="qualificationName" label="证书类别"></el-table-column>
                <el-table-column  property="certificateNo" label="证书编号">
                  <template #default="scope">
                    <span >{{scope.row.certificateNo ? scope.row.certificateNo : '-'}}</span>
                  </template>
                </el-table-column>
                <el-table-column  label="截止日期">
                   <template #default="scope">
                     <span >{{scope.row.validPeriod ? (scope.row.validPeriod).substring(0, 10): '-'}}</span>
                   </template>
                </el-table-column>
              </el-table>
           </el-popover>
           <span v-else>-</span>
          </template>
        </el-table-column>
        <template #empty style="width: 100%;">
          <gaussian-blur-empty-login @loginEmit="loginEmit" v-show="!islogin"></gaussian-blur-empty-login>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="fuzzyDataShow && returnLimit"></gaussian-blur-empty-vip>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length == 0"></gaussian-blur-empty-vip>
        </template>
      </el-table>
      <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length > 0"></gaussian-blur-empty-vip>
      <div class="table-page" v-show="!fuzzyDataShow && !returnLimit">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
    </block>
  </div>
</template>

<script>
import { oldForwardBG } from '@/api/forward.js'
import GaussianBlurEmptyLogin from '@/components/gaussian-blur/gaussian-blur-empty-login'
import GaussianBlurEmptyVip from '@/components/gaussian-blur/gaussian-blur-empty-vip'
import { mapGetters } from 'vuex'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  components: { GaussianBlurEmptyLogin, GaussianBlurEmptyVip },
  name: 'company-personnel-results-count',
  props: { companyId: String, num: Number },
  data () {
    return {
      isfalse: false,
      page: {
        total: this.num ? this.num : 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '姓名',
        property: 'name'
      }, {
        label: '岗位类别',
        property: 'qualificationName'
      }, {
        label: '岗位证书编号',
        property: 'certificateNo'
      }, {
        label: '参建项目',
        property: 'projectName'
      }, {
        label: '中标时间',
        property: 'timeSuccess'
      }],
      TableData: [],
      islogin: false,
      returnLimit: false,
      fuzzyDataShow: false
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  mounted () {
    if (this.idToken) {
      this.islogin = true
      this.initData()
    } else {
      this.islogin = false
    }
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (val) {
          if (this.idToken) {
            this.islogin = true
            this.initData()
          } else {
            this.islogin = false
          }
        }
      }
    },
    vipLevelChange: function (newValue, oldValue) {
      if (this.idToken) {
        this.islogin = true
        this.initData()
      } else {
        this.islogin = false
      }
    }
  },
  methods: {
    handleTo (e) {
      const vm = this
      e.id = e.projectId
      sessionStorage.removeItem('company-achievement-details-item')
      sessionStorage.setItem('company-achievement-details-item', JSON.stringify(e))
      const routeData = vm.$router.resolve({
        name: 'company-achievement-details'
      })
      window.open(routeData.href, '_blank')
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyPersonnelResults/getCompanyPersonnelResultsDetails',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      oldForwardBG(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const res = result.data
          const arr = []
          console.log('人员业绩')
          console.log(res)
          vm.page.total = res[0].dataTotal
          res.forEach((item, indexs) => {
            if (item.companyAchievementList.length > 0) {
              item.companyAchievementList.forEach((items, index) => {
                const obj = {
                  index: index === 0 ? indexs + 1 : null,
                  projectName: items.projectName,
                  timeSuccess: items.timeSuccess.substring(0, 10),
                  projectId: items.id,
                  name: item.name,
                  certificateNo: item.certificateNo,
                  qualificationName: item.qualificationName,
                  companyAchievementList: index === 0 ? item.companyAchievementList : [],
                  companyRegisteredPersonnelList: index === 0 ? item.companyRegisteredPersonnelList : []
                }
                arr.push(obj)
              })
            }
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 6 || columnIndex === 0) {
        return {
          rowspan: row.companyAchievementList.length,
          colspan: 1
        }
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  width: 100%;
}
</style>
