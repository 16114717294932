<template>
  <!-- 清算信息 -->
  <div>
    <block>
      <div id="LiquidationCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>清算信息 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-descriptions class="margin-top" :column="2" :size="size" border>
        <el-descriptions-item :cell-style="cellStyle"
        :header-cell-style="headerColor">
          <template #label>清算组负责人</template>
          <div style="width: 400px;">{{ TableData.leader || '-' }}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>清算组成员</template>
          <div style="width: 400px;">{{ TableData.member || '-' }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  name: 'liquidation-count',
  props: { companyId: String },
  data () {
    return {
      page: {
        total: 0,
        pageNum: 1
      },
      TableData: {
        leader: '',
        member: ''
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyOperatingState/accountingInformationList',
        dateBackgroundRequestType: 'get',
        data: {
          qcc_company_id: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.page.total = res.total
          if (res.list.length > 0) {
            vm.TableData = res.list[0]
          }
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-descriptions__label.is-bordered-label {
    font-weight: 700;
    color: #707071;
    background: #e8ebfc;
}
</style>
