<template>
  <!-- 备案信息 -->
  <div>
    <block>
      <div id="filingCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
      <div class="table-list-title dis-bet-cen">
        <div>备案信息 <span style="color: #5D6FE9;">{{page.total}}</span></div>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <template #empty style="width: 100%;">
          <gaussian-blur-empty-login @loginEmit="loginEmit" v-show="!islogin"></gaussian-blur-empty-login>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="fuzzyDataShow && returnLimit"></gaussian-blur-empty-vip>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length == 0"></gaussian-blur-empty-vip>
        </template>
      </el-table>
      <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length > 0"></gaussian-blur-empty-vip>
      <div class="table-page">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="100"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="page.total">
        </el-pagination>
      </div>
    </block>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import GaussianBlurEmptyLogin from '@/components/gaussian-blur/gaussian-blur-empty-login'
import GaussianBlurEmptyVip from '@/components/gaussian-blur/gaussian-blur-empty-vip'
import { mapGetters } from 'vuex'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'filing-count',
  props: { companyId: String, num: Number },
  components: { GaussianBlurEmptyLogin, GaussianBlurEmptyVip },
  data () {
    return {
      page: {
        total: this.num ? this.num : 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '备案地',
        property: 'province'
      }],
      TableData: [],
      islogin: false,
      returnLimit: false,
      fuzzyDataShow: false
    }
  },
  mounted () {
    if (this.idToken) {
      this.islogin = true
      this.initData()
    } else {
      this.islogin = false
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (val) {
          if (this.idToken) {
            this.islogin = true
            this.initData()
          } else {
            this.islogin = false
          }
        }
      }
    },
    vipLevelChange: function (newValue, oldValue) {
      if (this.idToken) {
        this.islogin = true
        this.initData()
      } else {
        this.islogin = false
      }
    }
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    pageChange (e) {
      this.page.pageNum = e
      this.initData()
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/tCompanyRecord/getTCompanyRecordListByModel',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId,
          pageNum: vm.page.pageNum,
          pageSize: 100
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data.list
          console.log(res)
          vm.page.total = res.total
          vm.TableData = res
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  width: 100%;
}
</style>
